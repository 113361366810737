import { useDispatch, useSelector } from 'react-redux';
import router, { useRouter } from 'next/router';
import Edit from 'components/Icons/Edit';
import PinAlt from 'components/Icons/PinAlt';



export default function StoreIndicator({ setActivePage }: any){

    const selectedStore = useSelector((state:any) => state?.bookingEvent.selectedStore);
    const storeLink = selectedStore?.description?.map_url? selectedStore?.description?.map_url:`https://maps.google.com/?q=${selectedStore?.lat},${selectedStore?.lng}`

    return (<div className="relative lg:ml-4 items-end"><div className="bg-brand-grey650 mb-4 relative rounded-[8px]">
      <div className="p-4 pb-2 flex leading-7">
        <PinAlt className="w-4 mr-2" /><div className="flex-1 font-medium text-lg"> Location </div><Edit onClick={() => { setActivePage(() => 1); router.push('#store'); }} fill={"black"} className="mt-1 mr-1 w-6 cursor-pointer" />
      </div>
      <div className="p-4 pt-0">
        <div className="mb-2">{selectedStore?.title}</div>
        <div className="text-sm text-brand-grey450 mb-2">{selectedStore?.address1}</div>
        <a className=" text-brand-blue text-sm font-medium cursor-pointer" rel="noreferrer" target="_blank" href={storeLink} >Show on Map</a>
      </div>
    </div></div>);
  }
  