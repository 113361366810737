
import { SVGProps } from 'react';

const CalendarEdit = ({
    width = 20,
    height = 21,
    fill = '#FF4F28',
    ...otherProps
}: SVGProps<SVGSVGElement>) => (
    <svg {...otherProps} width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.3 19.5C1.8 19.5 1.375 19.325 1.025 18.975C0.675 18.625 0.5 18.2 0.5 17.7V4.3C0.5 3.8 0.675 3.375 1.025 3.025C1.375 2.675 1.8 2.5 2.3 2.5H3.7V1.15C3.7 0.933333 3.77067 0.75 3.912 0.6C4.054 0.45 4.23333 0.375 4.45 0.375C4.68333 0.375 4.87067 0.45 5.012 0.6C5.154 0.75 5.225 0.933333 5.225 1.15V2.5H12.8V1.125C12.8 0.925 12.875 0.75 13.025 0.6C13.175 0.45 13.35 0.375 13.55 0.375C13.7667 0.375 13.9457 0.45 14.087 0.6C14.229 0.75 14.3 0.925 14.3 1.125V2.5H15.7C16.2 2.5 16.625 2.675 16.975 3.025C17.325 3.375 17.5 3.8 17.5 4.3V9.925H16V8.3H2V17.7C2 17.7833 2.02933 17.8543 2.088 17.913C2.146 17.971 2.21667 18 2.3 18H8.125V19.5H2.3ZM2 6.8H16V4.3C16 4.21667 15.971 4.146 15.913 4.088C15.8543 4.02933 15.7833 4 15.7 4H2.3C2.21667 4 2.146 4.02933 2.088 4.088C2.02933 4.146 2 4.21667 2 4.3V6.8ZM18.2 14.4L16.425 12.625L17.15 11.9C17.3 11.75 17.475 11.675 17.675 11.675C17.875 11.675 18.05 11.75 18.2 11.9L18.925 12.625C19.075 12.7583 19.15 12.929 19.15 13.137C19.15 13.3457 19.075 13.525 18.925 13.675L18.2 14.4ZM10.5 19.85V18.925C10.5 18.7917 10.5207 18.6707 10.562 18.562C10.604 18.454 10.675 18.3583 10.775 18.275L15.725 13.325L17.5 15.1L12.55 20.05C12.45 20.1333 12.346 20.2 12.238 20.25C12.1293 20.3 12.0167 20.325 11.9 20.325H10.95C10.8333 20.325 10.729 20.2793 10.637 20.188C10.5457 20.096 10.5 19.9833 10.5 19.85Z" fill="black" />
    </svg>
);

export default CalendarEdit;



