import React, { useEffect, useState, useRef, useMemo } from 'react';
import cx from 'classnames';
import useWindowSize from 'hooks/useWindowSize';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedService, } from 'redux/features/booking/bookingSlice';
import { bookingEvents } from 'functions/bookingEvents';
import router, { useRouter } from 'next/router';
import StoreIndicator from './Indicators/StoreIndicator';
import TagAlt from 'components/Icons/TagAlt';
import Clock from 'components/Icons/Clock';

const ServiceItem = ({ service, isSelected, children }: any) => {
    const [readMore, setReadMore] = useState(false);
    
  
    return (<div className={cx('m-[4px] cursor-pointer transition-all mb-2 flex relative ',' ')}>
      <div className={cx('flex-1 p-5 border border-brand-grey400 rounded-[8px] hover:p-[19px] hover:border-brand-orange hover:border-2',{ 'border-2 !border-brand-orange p-[19px]': isSelected })}>
        <div>
          <div className="font-medium mb-2" style={{width:'calc(100% - 100px)'}}>{service.name}</div>
          {service?.price && <div className="flex"><TagAlt fill="black" className="mt-1 mr-1" />${service.price}</div>}
          <div className={cx('text-sm text-brand-grey450 line-clamp-2 text-ellipsis html_container overflow-hidden', { 'line-clamp-none': readMore, 'h-10':!readMore })} dangerouslySetInnerHTML={{ __html: service.description }}></div>
          {(service?.description && service.description.length > 200) && <span className=" text-brand-blue cursor-pointer" onClick={(event) => {
            event.stopPropagation();
            setReadMore(() => !readMore)
          }
          }>Read {!readMore ? 'more' : 'less'}</span>}
        </div>
        <div className='absolute top-5 right-4 whitespace-nowrap flex leading-5 text-sm text-brand-grey450'><Clock className="mr-2" />{service?.duration} mins</div>
        {children}
      </div>
      
      
    </div>);
  }
  
  
  


export default function ServiceSelection({ services, setActivePage }: any) {
    const [selectedServiceIndex, setSelectedServiceIndex] = useState<any>(null);
    const { width } = useWindowSize();
  
    const selectedService = useSelector((state:any) => state?.bookingEvent.selectedService);
    const selectedStore = useSelector((state:any) => state?.bookingEvent.selectedStore);
    const dispatch = useDispatch();
    return (<div className='mt-5 lgx:px-2'>
      <h2 className=' lg:text-2xl lgx:text-lg font-bold'>Please select an appointment type</h2>
      <div className="flex justify-between lgx:flex-col">
        <div className='min-w-[70%] lgx:min-w-full lgx:mb-2 pt-3'>
          <div>
            {services.map((service: any, index: number) => ((selectedStore?.services.includes(service?.id) && service.providers.length > 0) &&<span key={index} onClick={() => {
              //if(width > 1024){
                //setCurrentState(() => Object.assign(currentState, { 'selectedService': services[index] }));
                dispatch(setSelectedService(service))
                setActivePage(() => 3)
                router.push('#provider')
                bookingEvents.Booking2_SelectService(service, selectedStore);
              //}
              setSelectedServiceIndex(() => index)
            }}><ServiceItem service={service} isSelected={selectedService?.id == service?.id || selectedServiceIndex == index}>
                {/*(selectedServiceIndex == index) && <UIButton className='mt-2 whitespace-nowrap min-w-full' onClick={() => {
                  setCurrentState(() => Object.assign(currentState, { 'selectedService': services[index] }));
                  dispatch(setSelectedService(service));
                  setActivePage(() => 3)
                  router.push('#provider')
                  bookingEvents.Booking2_SelectService(service, selectedStore)
                }}>Select Service</UIButton>*/}
              </ServiceItem></span>))}
          </div>
        </div>
        <div className='w-full h-full mt-4 min-w-[30%]'>
          <StoreIndicator setActivePage={setActivePage} />
        </div>
      </div>
    </div>); 
  } 
  
  
  