
import { SVGProps } from 'react';

const CalendarCancel = ({
  width = 22,
  height = 18,
  fill = '#FF4F28',
  ...otherProps
}: SVGProps<SVGSVGElement>) => (
    <svg {...otherProps} width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 13.925L7.225 15.7C7.09167 15.8333 6.921 15.9043 6.713 15.913C6.50433 15.921 6.325 15.85 6.175 15.7C6.025 15.55 5.95 15.375 5.95 15.175C5.95 14.975 6.025 14.8 6.175 14.65L7.95 12.875L6.175 11.1C6.04167 10.9667 5.971 10.7957 5.963 10.587C5.95433 10.379 6.025 10.2 6.175 10.05C6.325 9.9 6.5 9.825 6.7 9.825C6.9 9.825 7.075 9.9 7.225 10.05L9 11.825L10.775 10.05C10.9083 9.91667 11.0793 9.84567 11.288 9.837C11.496 9.829 11.675 9.9 11.825 10.05C11.975 10.2 12.05 10.375 12.05 10.575C12.05 10.775 11.975 10.95 11.825 11.1L10.05 12.875L11.825 14.65C11.9583 14.7833 12.0293 14.9543 12.038 15.163C12.046 15.371 11.975 15.55 11.825 15.7C11.675 15.85 11.5 15.925 11.3 15.925C11.1 15.925 10.925 15.85 10.775 15.7L9 13.925ZM2.3 19.5C1.8 19.5 1.375 19.325 1.025 18.975C0.675 18.625 0.5 18.2 0.5 17.7V4.3C0.5 3.8 0.675 3.375 1.025 3.025C1.375 2.675 1.8 2.5 2.3 2.5H3.7V1.15C3.7 0.933333 3.77067 0.75 3.912 0.6C4.054 0.45 4.23333 0.375 4.45 0.375C4.68333 0.375 4.87067 0.45 5.012 0.6C5.154 0.75 5.225 0.933333 5.225 1.15V2.5H12.8V1.125C12.8 0.925 12.875 0.75 13.025 0.6C13.175 0.45 13.35 0.375 13.55 0.375C13.7667 0.375 13.9457 0.45 14.087 0.6C14.229 0.75 14.3 0.925 14.3 1.125V2.5H15.7C16.2 2.5 16.625 2.675 16.975 3.025C17.325 3.375 17.5 3.8 17.5 4.3V17.7C17.5 18.2 17.325 18.625 16.975 18.975C16.625 19.325 16.2 19.5 15.7 19.5H2.3ZM2.3 18H15.7C15.7833 18 15.8543 17.971 15.913 17.913C15.971 17.8543 16 17.7833 16 17.7V8.3H2V17.7C2 17.7833 2.02933 17.8543 2.088 17.913C2.146 17.971 2.21667 18 2.3 18ZM2 6.8H16V4.3C16 4.21667 15.971 4.146 15.913 4.088C15.8543 4.02933 15.7833 4 15.7 4H2.3C2.21667 4 2.146 4.02933 2.088 4.088C2.02933 4.146 2 4.21667 2 4.3V6.8ZM2 6.8V4V6.8Z" fill={fill} />
    </svg>
);

export default CalendarCancel;
