import { FC, MouseEvent } from 'react';
import cx from 'classnames';
import Spinner from 'components/Spinner/Spinner';

interface UIButtonProps {
  color?: 'black';
  className?: string;
  block?: boolean;
  tag?: 'a' | 'button';
  // eslint-disable-next-line no-unused-vars
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  loading?: boolean;
  rounded?: boolean;
  customFontSize?: boolean;
  light?: boolean;
  size?: 'small' | 'default';
  insetLoading?: boolean;
  icon?: JSX.Element;
}
const UIButton: FC<UIButtonProps> = ({
  className = '',
  block = false,
  onClick,
  loading = false,
  disabled = false,
  rounded = false,
  customFontSize = false,
  light = false,
  size = 'default',
  insetLoading = false,
  icon,
  children
}) => {
  return (
    <button
      onClick={(event) => {
        if (disabled || loading) return;
        onClick?.(event);
      }}
      className={cx(
        'flex items-center justify-center text-center  font-medium text-white transition-colors duration-200',
        {
          'cursor-pointer sm:hover:text-brand-orange': !disabled && !loading,
          'cursor-not-allowed border border-brand-grey350 bg-brand-grey350':
            disabled || loading,
          'justify-center': loading,
          'w-full': block,
          'w-auto': !block,
          'rounded-[20px] px-[20px]': rounded,
          'rounded-[6px] px-[30px]': !rounded,
          'h-[48px]': size === 'default' && !rounded,
          'h-[37px] leading-[33px]': size === 'default' && rounded,
          'h-[40px]': size === 'small' && !rounded,
          'text-[13px] leading-[44px] sm:text-[14px]': !customFontSize,
          'bg-brand-black100 ': !light && !disabled && !loading,
          'border border-brand-grey400 bg-white text-brand-black100':
            light && !disabled && !loading,
          '!text-brand-black100': loading && light
        },
        className
      )}>
      {loading && !insetLoading ? (
        <Spinner fill={light ? '#2A2A2A' : '#FFFFFF'} />
      ) : (
        <>
          {insetLoading && loading && (
            <span className="mr-[1px]">
              <Spinner fill={light ? '#2A2A2A' : '#FFFFFF'} />
            </span>
          )}
          {!loading && !!icon && <span>{icon}</span>}
          <span className={cx('mb-[3px]', { 'ml-[6px] !mb-0': !!icon })}>
            {children}
          </span>
        </>
      )}
    </button>
  );
};

export default UIButton;