import { FC } from 'react';
import cx from 'classnames';
import Info from 'components/Icons/Info';
import UITooltip from './UITooltip';

interface UIRadioProps {
  options: { text: string; value: string; tooltip?: string | JSX.Element; icon: JSX.Element }[];
  vertical?: boolean;
  value?: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: string) => void;
  labelClassName?: string;
}
const UIRadioList: FC<UIRadioProps> = ({
  options,
  onChange,
  value,
  vertical,
  labelClassName = ''
}) => {
  return (
    <div className="flex">
      {options.map((option) => (
        <div
          key={option.value}
          className={cx('flex items-center text-brand-grey450 border border-brand-grey400 p-2 w-48 rounded-md', {
            'mb-[6px] flex-col last:mb-0': vertical,
            'mr-[10px] last:mr-0': !vertical,
            'cursor-pointer hover:text-brand-orange': option.value !== value,
            'cursor-default !text-white bg-brand-orange !border-brand-orange ': option.value === value
          })}
          onClick={() => onChange(option.value)}>
          {/* <UIRadioMarker selected={option.value === value} /> */}
          <span className='mx-auto flex'>
          {option?.icon && <span>{option?.icon}</span>}
          <span className={cx('ml-[10px] mb-[3px]', labelClassName)}>
            {option.text}
          </span>
          {!!option.tooltip && (
            <UITooltip
              id={'radio-list-' + option.value}
              message={option.tooltip}
              place="top"
              border={true}
              borderColour={'#D7D8D7'}
              textColour={'#000'}
              backgroundColour={'#FFF'}
              wrapperClassName="mb-[2px] ml-[12px]">
              <Info className="cursor-pointer" />
            </UITooltip>
          )}
          </span>
        </div>
      ))}
    </div>
  );
};

const UIRadioMarker: FC<{ selected: boolean }> = ({ selected }) => {
  return (
    <div className="flex h-[18px] w-[18px] flex-shrink-0 items-center justify-center rounded-full border border-brand-grey550 bg-white">
      {selected && (
        <span className="h-[10px] w-[10px] rounded-full bg-brand-orange"></span>
      )}
    </div>
  );
};

export const UIRadioCheck: FC<{ selected: boolean; onSelected?: () => void }> =
  ({ selected, children, onSelected }) => {
    return (
      <div
        className={cx('flex items-center text-brand-black100', {
          'cursor-pointer sm:hover:text-brand-orange': !selected,
          'cursor-default': selected
        })}
        onClick={() => onSelected?.()}>
        <UIRadioMarker selected={selected} />
        {!!children && <span className="ml-[10px]"> {children}</span>}
      </div>
    );
  };

export default UIRadioList;
