/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import ArrowAlt from 'components/Icons/ArrowAlt';
import useWindowSize from 'hooks/useWindowSize';

export interface BreadcrumbItem {
  id: number;
  href: string;
  text: string;
  forceLink?: boolean;
}
interface BreadcrumbProps {
  data: BreadcrumbItem[];
}

interface BreadcrumbLinkProps {
  item: BreadcrumbItem;
  index: number;
  itemsLength: number;
  forceLink?: boolean;
}

function BreadcrumbLink({ item, index, itemsLength, forceLink }: BreadcrumbLinkProps) {
  return <>
    {
      forceLink === true || index !== itemsLength - 1 ? (
        (<Link prefetch={false} href={`/${item.href}`} itemProp="item">

          <span
            itemProp="name"
            className={`flex items-center font-sans text-brand-grey450 ${
              'text-sm ' +
              (index == itemsLength - 1 ? 'font-medium' : 'font-normal')
            }`}>
            {item.text}
            <meta itemProp="position" content={`${index + 1}`} />
          </span>

        </Link>)
      ) : (
        <span
        suppressHydrationWarning
          itemProp="name"
          className={`flex items-center font-sans text-brand-grey450 ${
            'text-sm ' +
            (index == itemsLength - 1 ? 'font-medium' : 'font-normal')
          }`}>
          {item.text.replace('&amp;', '&')}
          <meta itemProp="position" content={`${index + 1}`} />
        </span>
      )
    }
  </>;
}

export default function Breadcrumb({ data: items }: BreadcrumbProps) {
  const wrapperRef = useRef<HTMLOListElement>(null);
  const { width } = useWindowSize();

  useEffect(() => {
    if (!wrapperRef?.current) return;

    const hasOverflow =
      wrapperRef.current.scrollWidth > wrapperRef.current.clientWidth;

    if (hasOverflow)
      wrapperRef.current?.scrollTo?.(wrapperRef.current.scrollWidth, 0);
  }, [wrapperRef, width]);

  return (
    <ol
      ref={wrapperRef}
      className="flex overflow-x-auto whitespace-nowrap items-start"
      style={{ 'flexBasis': '100%' }}
      itemScope
      itemType="https://schema.org/BreadcrumbList">
      {items.map((item, index) => (
        <li
          itemProp="itemListElement"
          itemScope
          itemType="https://schema.org/ListItem"
          key={item.id}
          className="mr-2 flex">
          {index == 0 ? null : (
            <span className="mr-[8px] mt-[6px] text-brand-grey350">
              <ArrowAlt fill="currentColor" height={12} width={6} />
            </span>
          )}
          <div className="group flex items-center text-[14px] mb-[8px] lg:mb-0">
            <BreadcrumbLink
              item={item}
              index={index}
              itemsLength={items.length}
              forceLink={item.forceLink}
            />
          </div>
        </li>
      ))}
    </ol>
  );
}
