
import { SVGProps } from 'react';

const Calendar = ({
  width = 22,
  height = 18,
  fill = '#2A2A2A',
  ...otherProps
}: SVGProps<SVGSVGElement>) => (
    <svg {...otherProps} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M7 1C6.44772 1 6 1.44772 6 2V3H4C2.89543 3 2 3.89543 2 5V21C2 22.1046 2.89543 23 4 23H20C21.1046 23 22 22.1046 22 21V5C22 3.89543 21.1046 3 20 3H18V2C18 1.44772 17.5523 1 17 1C16.4477 1 16 1.44772 16 2V3H8V2C8 1.44772 7.55228 1 7 1ZM16 5V4.5H8V5C8 5.55228 7.55228 6 7 6C6.44772 6 6 5.55228 6 5V4.5H4.5C3.94772 4.5 3.5 4.94772 3.5 5.5V20.5C3.5 21.0523 3.94772 21.5 4.5 21.5H19.5C20.0523 21.5 20.5 21.0523 20.5 20.5V5.5C20.5 4.94772 20.0523 4.5 19.5 4.5H18V5C18 5.55228 17.5523 6 17 6C16.4477 6 16 5.55228 16 5ZM7.5 8C6.67157 8 6 8.67157 6 9.5V11.5C6 12.3284 6.67157 13 7.5 13H9.5C10.3284 13 11 12.3284 11 11.5V9.5C11 8.67157 10.3284 8 9.5 8H7.5ZM8.3 9.5C7.85817 9.5 7.5 9.85817 7.5 10.3V10.7C7.5 11.1418 7.85817 11.5 8.3 11.5H8.7C9.14183 11.5 9.5 11.1418 9.5 10.7V10.3C9.5 9.85817 9.14183 9.5 8.7 9.5H8.3ZM14.5 8C13.6716 8 13 8.67157 13 9.5V11.5C13 12.3284 13.6716 13 14.5 13H16.5C17.3284 13 18 12.3284 18 11.5V9.5C18 8.67157 17.3284 8 16.5 8H14.5ZM15.3 9.5C14.8582 9.5 14.5 9.85817 14.5 10.3V10.7C14.5 11.1418 14.8582 11.5 15.3 11.5H15.7C16.1418 11.5 16.5 11.1418 16.5 10.7V10.3C16.5 9.85817 16.1418 9.5 15.7 9.5H15.3ZM6 15.5C6 14.6716 6.67157 14 7.5 14H9.5C10.3284 14 11 14.6716 11 15.5V17.5C11 18.3284 10.3284 19 9.5 19H7.5C6.67157 19 6 18.3284 6 17.5V15.5ZM7.5 16.3C7.5 15.8582 7.85817 15.5 8.3 15.5H8.7C9.14183 15.5 9.5 15.8582 9.5 16.3V16.7C9.5 17.1418 9.14183 17.5 8.7 17.5H8.3C7.85817 17.5 7.5 17.1418 7.5 16.7V16.3ZM14.5 14C13.6716 14 13 14.6716 13 15.5V17.5C13 18.3284 13.6716 19 14.5 19H16.5C17.3284 19 18 18.3284 18 17.5V15.5C18 14.6716 17.3284 14 16.5 14H14.5ZM15.3 15.5C14.8582 15.5 14.5 15.8582 14.5 16.3V16.7C14.5 17.1418 14.8582 17.5 15.3 17.5H15.7C16.1418 17.5 16.5 17.1418 16.5 16.7V16.3C16.5 15.8582 16.1418 15.5 15.7 15.5H15.3Z" fill="#FF8200" />
    </svg>
);

export default Calendar;
