import { SVGProps } from 'react';

const Basket = ({
  width = 24,
  height = 24,
  fill = '#FF8200',
  ...otherProps
}: SVGProps<SVGSVGElement>) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
        <path d="M4.5 13.25C4.15 13.25 3.854 13.129 3.612 12.887C3.37067 12.6457 3.25 12.35 3.25 12C3.25 11.65 3.37067 11.3543 3.612 11.113C3.854 10.871 4.15 10.75 4.5 10.75C4.85 10.75 5.146 10.871 5.388 11.113C5.62933 11.3543 5.75 11.65 5.75 12C5.75 12.35 5.62933 12.6457 5.388 12.887C5.146 13.129 4.85 13.25 4.5 13.25ZM4.5 7.25C4.15 7.25 3.854 7.129 3.612 6.887C3.37067 6.64567 3.25 6.35 3.25 6C3.25 5.65 3.37067 5.354 3.612 5.112C3.854 4.87067 4.15 4.75 4.5 4.75C4.85 4.75 5.146 4.87067 5.388 5.112C5.62933 5.354 5.75 5.65 5.75 6C5.75 6.35 5.62933 6.64567 5.388 6.887C5.146 7.129 4.85 7.25 4.5 7.25ZM4.5 19.25C4.15 19.25 3.854 19.129 3.612 18.887C3.37067 18.6457 3.25 18.35 3.25 18C3.25 17.65 3.37067 17.3543 3.612 17.113C3.854 16.871 4.15 16.75 4.5 16.75C4.85 16.75 5.146 16.871 5.388 17.113C5.62933 17.3543 5.75 17.65 5.75 18C5.75 18.35 5.62933 18.6457 5.388 18.887C5.146 19.129 4.85 19.25 4.5 19.25ZM9 18.75C8.78333 18.75 8.60433 18.6793 8.463 18.538C8.321 18.396 8.25 18.2167 8.25 18C8.25 17.7833 8.321 17.604 8.463 17.462C8.60433 17.3207 8.78333 17.25 9 17.25H20C20.2167 17.25 20.396 17.3207 20.538 17.462C20.6793 17.604 20.75 17.7833 20.75 18C20.75 18.2167 20.6793 18.396 20.538 18.538C20.396 18.6793 20.2167 18.75 20 18.75H9ZM9 12.75C8.78333 12.75 8.60433 12.679 8.463 12.537C8.321 12.3957 8.25 12.2167 8.25 12C8.25 11.7833 8.321 11.604 8.463 11.462C8.60433 11.3207 8.78333 11.25 9 11.25H20C20.2167 11.25 20.396 11.3207 20.538 11.462C20.6793 11.604 20.75 11.7833 20.75 12C20.75 12.2167 20.6793 12.3957 20.538 12.537C20.396 12.679 20.2167 12.75 20 12.75H9ZM9 6.75C8.78333 6.75 8.60433 6.679 8.463 6.537C8.321 6.39567 8.25 6.21667 8.25 6C8.25 5.78333 8.321 5.60433 8.463 5.463C8.60433 5.321 8.78333 5.25 9 5.25H20C20.2167 5.25 20.396 5.321 20.538 5.463C20.6793 5.60433 20.75 5.78333 20.75 6C20.75 6.21667 20.6793 6.39567 20.538 6.537C20.396 6.679 20.2167 6.75 20 6.75H9Z" fill={fill} />
    </svg>
);

export default Basket;


