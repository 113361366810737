import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedProvider, setAvailability } from 'redux/features/booking/bookingSlice';
import useWindowSize from 'hooks/useWindowSize';
import cx from 'classnames';
import router, { useRouter } from 'next/router';
import Image from "next/legacy/image";
import axios from 'axios';
import ServiceIndicator from './Indicators/ServiceIndicator';
import StoreIndicator from './Indicators/StoreIndicator';
import { format, parse } from "date-fns";
import Clock from 'components/Icons/Clock';

const ProviderItem = ({ name, description, picture, children, isSelected, availability, providerId }: any) => {
    const [readMore, setReadMore] = useState(false);
    const availabilityInfo = useMemo(() => availability?.filter((item:any) => item?.provider_id == providerId), [providerId, availability])

    return (<div className={cx('cursor-pointer transition-all flex', {'grayscale':(availability && availabilityInfo.length == 0)})}>
      <div className={cx('flex-col border mb-2 border-brand-grey400 hover:border-brand-orange hover:border-2 w-full rounded-[8px] p-5 hover:p-[19px] ', { 'border-2 !border-brand-orange mb-5 p-[19px]': isSelected })}>
        <div className='w-full flex lgx:flex-row-reverse' >
          {picture && <div className='m-1 w-20 h-20 lg:w-32 lg:h-32 mx-auto bg-brand-grey500 rounded-full relative'><Image className='rounded-full' width={70} height={70} layout='responsive' objectFit="cover" alt={name} src={picture} /></div>}
          <div className="lg:ml-4 lgx:ml-1 flex-1 relative">
            <div className="font-medium mt-3 lg:mb-2">{name}</div>
            
            {(availability && (availability.length == 0 || availabilityInfo.length == 0)) && <div className='mb-2 lg:absolute lg:right-0 lg:top-2 lg:pt-2 lgx:mt-1 sm:flex text-sm text-brand-grey450 '><span className="flex">Provider doesn`t have available time slot.</span></div>}
            {(availabilityInfo?.[0]?.date && availabilityInfo?.[0]?.time) && <div className='mb-2 lg:absolute lg:right-0 lg:top-2 lg:pt-2 lgx:mt-1 sm:flex text-sm text-brand-grey450 '><span className="flex"><Clock className='mr-1' />Next availability:</span> <span className="text-brand-black100 sm:ml-1">{format(new Date(parse(availabilityInfo?.[0]?.date, "yyyy-MM-dd", new Date()).setHours(availabilityInfo?.[0]?.time.split(":")[0])).setMinutes(availabilityInfo?.[0]?.time.split(":")[1]), 'dd MMMM YYY - p')}</span></div>}
            <div className={cx('text-sm text-brand-grey450 text-ellipsis line-clamp-3 ', { 'line-clamp-none': readMore })}>
              <div dangerouslySetInnerHTML={{ __html: description }}></div>
            </div>
            {description.length > 200 && <div className="cursor-pointer text-brand-blue" onClick={(event) => {
              event.stopPropagation();
              setReadMore(() => !readMore)
            }
            }>Read {!readMore ? 'more' : 'less'}</div>}
  
          </div>
  
        </div>
        {children}
      </div>
    </div>);
  }
  

export default function ProviderSelection({ providers, setActivePage }: any){
    const [selectedProviderIndex, setSelectedProviderIndex] = useState<any>(null);
    const { width } = useWindowSize();
  
    const dispatch =  useDispatch();
    const selectedService = useSelector((state:any) => state?.bookingEvent.selectedService);
    const selectedStore = useSelector((state:any) => state?.bookingEvent.selectedStore);
    const selectedProvider = useSelector((state:any) => state?.bookingEvent.selectedProvider);
    //const [availability, setAvailability] = useState<any>(null);
    const availability = useSelector((state:any) => state?.bookingEvent.availability);

    const providerList = useMemo(() => providers.filter((item:any) => item.services.includes(parseInt(selectedService?.id)) && item.locations.includes(selectedStore?.id)), [selectedService, selectedStore, providers])
  
    useEffect(() => {
      dispatch(setAvailability(null))
      let url = `${process.env.NEXT_PUBLIC_LARAVEL_URL}/bookings/availabilities?service_id=${selectedService?.id}`;
      const availabilityArray:any = [];
      if(selectedStore?.id){
        providers.map((item: any) => {
          if(selectedService.providers.includes(parseInt(item.id)) && selectedStore.providers.includes(item.id)){
            //url += `&provider_ids[]=${item.id}`
            availabilityArray.push(new Promise((resolve, reject) => {
              axios.get(url+`&provider_ids[]=${item.id}`, {
                headers: {
                  'region': router.locale as string
                }
                }).then((res) => {
                //dispatch(setAvailability(res.data))
                resolve(res.data)
              });
            }))
          }
        })
      }

      Promise.all(availabilityArray).then((res) => {
        let allDates:any = res.map((item:any) => [...item]);
        allDates = allDates.flat();
        let now:any = new Date();
        
        /*const anyOptomDate:any = allDates.sort((a:any,b:any) => {
          const [aDate, bDate] = [a,b].map((d:any) => Math.abs(new Date(parse(d.date+' '+d.time,"yyyy-MM-dd hh:mm:ss", new Date())).valueOf() - now.valueOf()));
          
          return aDate - bDate
        })*/
        
        const anyOptomDate:any = allDates.sort((a:any, b:any) => {
          const aDate:any = new Date(`${a.date}T${a.time}`);
          const bDate:any = new Date(`${b.date}T${b.time}`);
          return aDate - bDate;
        });
        
        if(anyOptomDate?.[0]?.date){
          if(anyOptomDate?.[0]?.date && anyOptomDate?.[0]?.time){
            allDates.push({"date": anyOptomDate?.[0]?.date, "time": anyOptomDate?.[0]?.time, "provider_id": 0})
          }
          dispatch(setAvailability([...allDates]))
        }else{
          dispatch(setAvailability([...allDates]))
        }
        //dispatch(setAvailability(res[0].concat(res[1])))
      })

      
      
      
  
    }, [selectedService?.id]);


    return (<div className='mt-5 lgx:px-2'>
      <h2 className=' lg:text-2xl lgx:text-lg font-bold'>Please select a provider</h2>
      <div className="flex justify-between lgx:flex-col">
        <div className='min-w-[70%] pt-3'>
          <div className="">
            {providerList.map((item: any, idx: any) => (<span key={idx} onClick={() => {
              //if(width > 1024){
                if(!availability || availability.length == 0) return;
                if(availability?.filter((a:any) => a?.provider_id == item.id).length == 0) return;

                dispatch(setSelectedProvider(providerList[idx]))
                //setCurrentState(() => Object.assign(currentState, { 'selectedProvider': providers[idx] }));
                setActivePage(() => 4)
                router.push('#date')
              //}
              setSelectedProviderIndex(() => idx)
            }}>{!(providerList.length == 2 && item.id == 0) && <ProviderItem availability={availability} providerId={item.id} isSelected={selectedProvider?.id == item?.id || selectedProviderIndex === idx} name={item.name} description={item.description} picture={item.picture_path}>
                {
                /* {selectedProviderIndex === idx && <UIButton className="flex-1 mt-2 whitespace-nowrap min-w-full" onClick={() => {
                  setCurrentState(() => Object.assign(currentState, { 'selectedProvider': providers[idx] }));
                  dispatch(setSelectedProvider(providers[idx]))
                  setActivePage(() => 4)
                  router.push('#date') 
                }}>Select Provider</UIButton>} */}
              </ProviderItem>}</span>))}
          </div>
        </div>
        <div className="min-w-[30%] mt-4 h-full">
          <ServiceIndicator setActivePage={setActivePage} />
          <StoreIndicator setActivePage={setActivePage} />
        </div>
      </div>
    </div>);
  }