import { SVGProps } from 'react';

const CalendarAdd = ({
    width = 22,
    height = 18,
    fill = 'black',
    ...otherProps
}: SVGProps<SVGSVGElement>) => (
    <svg {...otherProps} width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.9998 5.2998V2.2998H5.9998V5.2998H4.4998V1.6998C4.4998 1.4498 4.58747 1.23714 4.7628 1.0618C4.93747 0.887138 5.14981 0.799805 5.39981 0.799805H14.5998C14.8498 0.799805 15.0625 0.887138 15.2378 1.0618C15.4125 1.23714 15.4998 1.4498 15.4998 1.6998V5.2998H13.9998ZM2.2998 6.7998H17.6998H4.4998H2.2998ZM15.7998 9.2998C16.0831 9.2998 16.3208 9.2038 16.5128 9.0118C16.7041 8.82047 16.7998 8.58314 16.7998 8.2998C16.7998 8.01647 16.7041 7.7788 16.5128 7.5868C16.3208 7.39547 16.0831 7.2998 15.7998 7.2998C15.5165 7.2998 15.2788 7.39547 15.0868 7.5868C14.8955 7.7788 14.7998 8.01647 14.7998 8.2998C14.7998 8.58314 14.8955 8.82047 15.0868 9.0118C15.2788 9.2038 15.5165 9.2998 15.7998 9.2998ZM6.2998 15.9998H13.6998C13.7831 15.9998 13.8541 15.9708 13.9128 15.9128C13.9708 15.8541 13.9998 15.7831 13.9998 15.6998V11.7248H5.9998V15.6998C5.9998 15.7831 6.02914 15.8541 6.0878 15.9128C6.1458 15.9708 6.21647 15.9998 6.2998 15.9998ZM6.2998 17.4998C5.7998 17.4998 5.3748 17.3248 5.02481 16.9748C4.67481 16.6248 4.4998 16.1998 4.4998 15.6998V13.4998H1.6998C1.43314 13.4998 1.21647 13.4125 1.0498 13.2378C0.883138 13.0625 0.799805 12.8498 0.799805 12.5998V7.7998C0.799805 7.0998 1.04147 6.50814 1.5248 6.0248C2.00814 5.54147 2.5998 5.2998 3.2998 5.2998H16.6998C17.4165 5.2998 18.0125 5.54147 18.4878 6.0248C18.9625 6.50814 19.1998 7.0998 19.1998 7.7998V12.5998C19.1998 12.8498 19.1165 13.0625 18.9498 13.2378C18.7831 13.4125 18.5665 13.4998 18.2998 13.4998H15.4998V15.6998C15.4998 16.1998 15.3248 16.6248 14.9748 16.9748C14.6248 17.3248 14.1998 17.4998 13.6998 17.4998H6.2998ZM17.6998 11.9998V7.7998C17.6998 7.51647 17.6041 7.2788 17.4128 7.0868C17.2208 6.89547 16.9831 6.7998 16.6998 6.7998H3.2998C3.01647 6.7998 2.77914 6.89547 2.5878 7.0868C2.3958 7.2788 2.2998 7.51647 2.2998 7.7998V11.9998H4.4998V10.2248H15.4998V11.9998H17.6998Z" fill={fill} />
    </svg>
);

export default CalendarAdd;
