import { SVGProps } from 'react';

const ArrowAlt = ({
  width = 6,
  height = 6,
  fill = '#00AEC7',
  ...otherProps
}: SVGProps<SVGSVGElement>) => (
  <svg
    height={height + 'px'}
    width={width + 'px'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 6 10"
    {...otherProps}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.24306 -0.000871548L0.394531 0.847656L3.78819 4.24132L0.39366 7.63585L1.24219 8.48437L4.63672 5.08984L4.63717 5.09029L5.4857 4.24177L1.24306 -0.000871548Z"
      fill={fill}
    />
  </svg>
);

export default ArrowAlt;
