import { SVGProps } from 'react';

const KeyboardInput = ({
  width = 18,
  height = 18,
  fill = '#959595',
  ...otherProps
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
    {...otherProps}>
    <circle cx="9" cy="9" r="9" fill="#EAEAEA" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 6C9.55228 6 10 5.55228 10 5C10 4.44772 9.55228 4 9 4C8.44772 4 8 4.44772 8 5C8 5.55228 8.44772 6 9 6ZM9 7C8.44772 7 8 7.44772 8 8V13C8 13.5523 8.44772 14 9 14C9.55228 14 10 13.5523 10 13V8C10 7.44772 9.55228 7 9 7Z"
      fill={fill}
    />
  </svg>
);

export default KeyboardInput;

export const InfoIcon = ({
	width = 18,
	height = 18,
	fill = "",
	...otherProps
}: SVGProps<SVGSVGElement>) => (

	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="9" cy="9" r="9" fill="#FF8200"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M9 6C9.55228 6 10 5.55228 10 5C10 4.44772 9.55228 4 9 4C8.44772 4 8 4.44772 8 5C8 5.55228 8.44772 6 9 6ZM9 7C8.44772 7 8 7.44772 8 8V13C8 13.5523 8.44772 14 9 14C9.55228 14 10 13.5523 10 13V8C10 7.44772 9.55228 7 9 7Z" fill="white"/>
	</svg>
);

export const InfoIconAlt = ({
  width = 16,
  height = 16,
  fill = "",
  ...otherProps
}: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
<circle cx="8" cy="8" r="7.5" stroke="#616161"/>
<path fillRule="evenodd" clipRule="evenodd" d="M7.99997 5.32465C8.49089 5.32465 8.88886 4.92668 8.88886 4.43576C8.88886 3.94484 8.49089 3.54688 7.99997 3.54688C7.50905 3.54688 7.11108 3.94484 7.11108 4.43576C7.11108 4.92668 7.50905 5.32465 7.99997 5.32465ZM7.99997 6.21599C7.50905 6.21599 7.11108 6.61396 7.11108 7.10488V11.5493C7.11108 12.0402 7.50905 12.4382 7.99997 12.4382C8.49089 12.4382 8.88886 12.0402 8.88886 11.5493V7.10488C8.88886 6.61396 8.49089 6.21599 7.99997 6.21599Z" fill="#616161"/>
</svg>
);