import { SVGProps } from 'react';

const Clock = ({
  width = 20,
  height = 20,
  ...otherProps
}: SVGProps<SVGSVGElement>) => (
    <svg {...otherProps} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.062 13.312C12.2287 13.4787 12.4303 13.562 12.667 13.562C12.903 13.562 13.1113 13.472 13.292 13.292C13.472 13.1113 13.562 12.8993 13.562 12.656C13.562 12.4133 13.472 12.2087 13.292 12.042L10.875 9.64599V6.72899C10.875 6.49299 10.7917 6.29166 10.625 6.12499C10.4583 5.95833 10.25 5.87499 9.99999 5.87499C9.76399 5.87499 9.55899 5.96166 9.38499 6.13499C9.21166 6.30899 9.12499 6.51399 9.12499 6.74999V9.97899C9.12499 10.0903 9.14599 10.198 9.18799 10.302C9.22933 10.406 9.29166 10.4997 9.37499 10.583L12.062 13.312ZM9.99999 18.333C8.84733 18.333 7.76399 18.1143 6.74999 17.677C5.73599 17.2397 4.85399 16.646 4.10399 15.896C3.35399 15.146 2.76033 14.264 2.32299 13.25C1.88566 12.236 1.66699 11.1527 1.66699 9.99999C1.66699 8.84733 1.88566 7.76399 2.32299 6.74999C2.76033 5.73599 3.35399 4.85399 4.10399 4.10399C4.85399 3.35399 5.73599 2.76033 6.74999 2.32299C7.76399 1.88566 8.84733 1.66699 9.99999 1.66699C11.1527 1.66699 12.236 1.88566 13.25 2.32299C14.264 2.76033 15.146 3.35399 15.896 4.10399C16.646 4.85399 17.2397 5.73599 17.677 6.74999C18.1143 7.76399 18.333 8.84733 18.333 9.99999C18.333 11.1527 18.1143 12.236 17.677 13.25C17.2397 14.264 16.646 15.146 15.896 15.896C15.146 16.646 14.264 17.2397 13.25 17.677C12.236 18.1143 11.1527 18.333 9.99999 18.333ZM9.99999 16.583C11.8193 16.583 13.3713 15.9407 14.656 14.656C15.9407 13.3713 16.583 11.8193 16.583 9.99999C16.583 8.18066 15.9407 6.62866 14.656 5.34399C13.3713 4.05933 11.8193 3.41699 9.99999 3.41699C8.18066 3.41699 6.62866 4.05933 5.34399 5.34399C4.05933 6.62866 3.41699 8.18066 3.41699 9.99999C3.41699 11.8193 4.05933 13.3713 5.34399 14.656C6.62866 15.9407 8.18066 16.583 9.99999 16.583Z" fill="#959595"/>
    </svg>
    
);

export default Clock;



