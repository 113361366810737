import { addDataLayer } from 'lib/ga';
import router, { useRouter } from 'next/router';
import { format } from "date-fns";
import { v4 as uuidv4 } from 'uuid';
import { getSession } from './session';

let bookingType:any = null;

export const bookingEvents = {
        Booking1_ViewStore: () => {
            const bookingParams = bookingEvents.checkUserId(router.router?.query, true);
            addDataLayer({
                event: 'Booking1_ViewStore',
                params: bookingParams
            }, false)
        },
        Booking1_SelectStore: (selectedStore:any) => {
            const bookingParams = bookingEvents.checkUserId({ 'storeName': selectedStore?.title, 'country': selectedStore?.country_id, 'state': selectedStore?.address2, 'city': selectedStore?.city, ...router.router?.query })
            addDataLayer({
                event: 'Booking1_SelectStore',
                params: bookingParams
            }, false)
        },
        Booking2_SelectService: (selectedService:any, selectedStore:any) => {
            //console.log(currentState);
            const bookingParams = bookingEvents.checkUserId({ 'serviceName':selectedService?.name, 'storeName': selectedStore?.title, 'country': selectedStore?.country_id, 'state': selectedStore?.address2, 'city': selectedStore?.city, ...router.router?.query })
            addDataLayer({
                event: 'Booking2_SelectService',
                params: bookingParams
            }, false)
        },
        Booking3_SelectTime: (selectedProvider:any, selectedService:any, selectedStore:any, time:any) => {
            //console.log(currentState);
            const bookingParams = bookingEvents.checkUserId({ 'providerName':selectedProvider?.name, 'serviceName':selectedService?.name, 'storeName': selectedStore?.title, 'country': selectedStore?.country_id, 'state': selectedStore?.address2, 'city': selectedStore?.city, 'date': format(time, "dd MMM yyyy"), ...router.router?.query, 'price': selectedService?.price, 'time': new Date(time).toISOString()  })
            addDataLayer({
                event: 'Booking3_SelectTime',
                params: bookingParams
            }, false)
        },
        Booking4_StartConfirmation: (selectedProvider:any, selectedService:any, selectedStore:any, optionValue:any) => {
            const bookingParams = bookingEvents.checkUserId({ 'providerName':selectedProvider?.name, 'serviceName':selectedService?.name, 'storeName': selectedStore?.title, 'country': selectedStore?.country_id, 'state': selectedStore?.address2, 'city': selectedStore?.city, ...router.router?.query })
            bookingType = optionValue;
            addDataLayer({
                event: 'Booking4_StartConfirmation',
                params: bookingParams
            }, false)
        },
        Booking5_Success: (selectedProvider:any, selectedService:any, selectedStore:any, bookingInfo:any, bookingResponse:any, additionalFields:any) => {
            let calculatedField:any = {};
            const bookingParams = bookingEvents.checkUserId({'option':bookingType, 'patientName':bookingInfo?.client_name,'email':bookingInfo?.client_email,'phone':bookingInfo?.client_phone, 'providerName':selectedProvider?.name, 'serviceName':selectedService?.name, 'storeName': selectedStore?.title, 'country': selectedStore?.country_id, 'state': selectedStore?.address2, 'city': selectedStore?.city, ...router.router?.query, 'additional_fields': calculatedField, 'code': bookingResponse?.code })
            let titles = additionalFields.forEach((item:any,idx:any) => {
                let title:any = null;
                if(item.title.toLowerCase().indexOf('medicare')>-1) title = 'medicare';
                if(item.title.toLowerCase().indexOf('irn')>-1) title = 'irn';
                if(item.title.toLowerCase().indexOf('date of birth')>-1 || item.type == 'date') title = 'dob';
                if(item.title.toLowerCase().indexOf('mention')>-1) title = 'notes';
                
                calculatedField[title] = bookingInfo?.additional_fields[item.name]
            })
            

            addDataLayer({
                event: 'Booking5_Success',
                params: bookingParams
            }, false)


            localStorage.setItem('booking_session', uuidv4())
        },
        BookingSuccess_AddtoCalendar: () => {
            const bookingParams = bookingEvents.checkUserId({}, true)
            addDataLayer({
                event: 'BookingSuccess_AddtoCalendar',
                params: bookingParams
            }, false)

        },
        BookingSuccess_Print: () => {
            const bookingParams = bookingEvents.checkUserId({}, true)
            addDataLayer({
                event: 'BookingSuccess_Print',
                params: bookingParams
            }, false)
        },
        BookingSuccess_Cancel: () => {
            const bookingParams = bookingEvents.checkUserId({}, true)
            addDataLayer({
                event: 'BookingSuccess_Cancel',
                params: bookingParams
            }, false)
        },
        BookingSuccess_Reschedule: () => {
            localStorage.setItem('booking_session', uuidv4())
            const bookingParams = bookingEvents.checkUserId({}, true)
            addDataLayer({
                event: 'BookingSuccess_Reschedule',
                params: bookingParams
            }, false)
        },
        checkUserId: (params:any, withFullUrl = false) => {
            let datalayerParams:any = { ...params };
            const parseJwt = (token:any) => {
                var base64Url = token.split('.')[1];
                var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));
            
                return JSON.parse(jsonPayload);
            }

            if(JSON.parse(localStorage.getItem('user') as string)?.ID){
                datalayerParams['userId'] = JSON.parse(localStorage.getItem('user') as string)?.ID;
            }

            

            if(!localStorage.getItem('booking_session')){
               localStorage.setItem('booking_session', uuidv4())
            }

            if(getSession()){
                datalayerParams['customer_id'] = parseJwt(getSession() as string)?.data?.customer_id;
            }

            datalayerParams['sessionId'] = localStorage.getItem('booking_session');
            datalayerParams['eventId'] = uuidv4();
            datalayerParams['eventTime'] = new Date().toISOString();
            if(window && withFullUrl){
                datalayerParams['fullUrl'] = window.location.href;
            }

            if(window && document && document?.referrer){
                datalayerParams['referrer'] = document.referrer;
            }
            // if(document){
            //     const gaValues:any = {};
            //     let cookies:any = document.cookie.split("; ");
            //     cookies.forEach(cookie => {
            //         if (cookie.startsWith("_ga")) {
            //           const [key, value] = cookie.split("=");
            //           gaValues[key] = value;
            //         }
            //       });
            //     Object.assign(datalayerParams, gaValues);
            // }


            return datalayerParams
        }
    }