import { SVGProps } from 'react';

const CheckmarkAlt = ({
  width = 15,
  height = 15,
  fill = '#00AEC7',
  ...otherProps
}: SVGProps<SVGSVGElement>) => (
  
  <svg width={width} height={height} {...otherProps} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="7.5" cy="7.5" r="7.5" fill={fill} />
    <path fillRule="evenodd" clipRule="evenodd" d="M7.29171 9.8853L7.29278 9.88638L6.11611 11.0631L3.00049 7.94743L4.17716 6.77076L6.11504 8.70863L10.8237 4L12.0003 5.17667L7.29171 9.8853Z" fill="white" />
  </svg>

);

export default CheckmarkAlt;
