import { useDispatch, useSelector } from 'react-redux';
import router, { useRouter } from 'next/router';
import Edit from 'components/Icons/Edit';
import Calendar from 'components/Icons/Calendar';
import { format } from "date-fns";


export default function DateTimeIndicator({ setActivePage }: any) {
    const selectedTime = useSelector((state: any) => state.bookingEvent.selectedTime);
    return (<div className="relative lg:ml-4 items-end"><div className="bg-brand-grey650 mb-4 relative rounded-[8px]">
      <div className="p-4 pb-2 flex items-center">
        <Calendar className="w-6 mt-0 mr-2" /><div className="flex-1 font-medium text-lg"> Date & Time </div><Edit onClick={() => { setActivePage(() => 4);router.push('#date') }} fill={"black"} className="mt-1 mr-1 w-6 cursor-pointer" />
      </div>
      <div className="p-4 pt-0">
        <div className="mb-0">{selectedTime && format(selectedTime, 'cccc dd MMMM yyyy')}</div>
        <div className="mb-2">{selectedTime && format(selectedTime, 'p' )}</div>
      </div>
    </div></div>);
  }