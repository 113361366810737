import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAvailability, setSelectedStore, } from 'redux/features/booking/bookingSlice';
import useWindowSize from 'hooks/useWindowSize';
import cx from 'classnames';
import router, { useRouter } from 'next/router';
import BulletedList from 'components/Icons/BulletedList';
import PinAlt from 'components/Icons/PinAlt';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { bookingEvents } from 'functions/bookingEvents';
import UIButton from 'components/Others/UIButton';
import StoreNotFound from 'components/Icons/StoreNotFound';

const StoreLocationItem = ({ isSelected, pos, title, address, children }: any) => {
  return (<div className={cx('flex group cursor-pointer justify-left items-center transition-all mb-2', '')}>
    <div className={cx("flex w-full border rounded-[8px] hover:py-[19px] hover:px-[7px] px-[8px] py-[20px] border-brand-grey400 hover:border-brand-orange hover:border-2", { 'border-2 !border-brand-orange px-[7px] py-[19px]': isSelected })}>
      <div className={cx(' w-10 h-10 border-2 border-brand-grey500 rounded-full text-center ml-4 mr-4 leading-8 pb-0.5 font-medium', { ' bg-brand-orange text-white !border-brand-orange': isSelected })}>{pos}</div>
      <div className='flex-1 -mt-2'>
        <div className='text-lg font-medium mb-1'>{title}</div>
        <div className='text-sm text-brand-grey450'>{address}</div>
      </div>
      {children}
      {/*<div className="mr-2 flex leading-5 text-sm text-brand-grey450"><Clock className="mr-2" />{hours}</div>*/}
    </div>
  </div>);
}


export default function StoreSelection({ stores, setActivePage }: any){
    const [lat, setLat] = useState(parseFloat(stores[Object.keys(stores)?.[0]]?.[0]?.lat));
    const [lng, setLng] = useState(parseFloat(stores[Object.keys(stores)?.[0]]?.[0]?.lng));
    const { width } = useWindowSize();
    const [searchValue, setSearchValue] = useState<string>('');
    const mobileSlider: any = useRef(null);
    const [mobileViewActiveState, setMobileViewActiveState] = useState<any>(0);
    const [selectedStoreIndex, setSelectedStoreIndex] = useState<any>(null);
    const [activeMarker, setActiveMarker] = useState(null);
  
    let count = 1;
  
    const onlyStores: any = useMemo(() => Object.values(stores || [])?.reduce((item: any, acc: any) => [...item, ...acc]), [stores])
    const psudoIdsByStoreId = useMemo(() => onlyStores.reduce((item: any, acc: any) => ({ ...item, [acc.id]: count++ }), {}), [onlyStores, count])
  
    
    /*const sortedRegionList = useMemo(
      () =>
        Object.keys(AVAILABLE_REGIONS)
          .map((regionKey) => ({
            key: regionKey,
            ...AVAILABLE_REGIONS[regionKey as RegionKey]
          }))
          .sort((prev) => (prev.key.toLowerCase() === router.locale ? -1 : 1)),
      [router.locale]
    );*/
  
    const checkSearch = (item: any) => {
      const founded = item.title.toLocaleLowerCase().indexOf(searchValue.toLocaleLowerCase()) > -1 ||
        item.address1.toLocaleLowerCase().indexOf(searchValue.toLocaleLowerCase()) > -1
      return founded;
    }
    //const storeMap:any = [];
  
    const handleSearchChange = (e: any) => setSearchValue(e.target.value);
  
    const handleActiveMarker = (marker:any) => {
      if (marker === activeMarker) {
        return;
      }
      setActiveMarker(marker);
    };
  
    useEffect(() => {
      function handleScroll(evt: any) {
        /*console.log(evt.target.scrollLeft);
        console.log(evt.target.scrollWidth);
        console.log("offsetleft", evt.target.offsetLeft)*/
        const itemWidth = width - 96;
        const latestScrollPosition = Math.round(evt.target.scrollLeft / itemWidth) * itemWidth;
        const storeIndex = Math.round((latestScrollPosition) / (evt.target.scrollWidth / onlyStores.length));
  
        if (parseFloat(onlyStores[storeIndex]?.lat) && parseFloat(onlyStores[storeIndex]?.lng)) {
          setLat(() => parseFloat(onlyStores[storeIndex].lat));
          setLng(() => parseFloat(onlyStores[storeIndex].lng));
          //setActiveMarker(storeIndex);
        }
      }
  
      (mobileViewActiveState == 1 && width <= 1024) && mobileSlider.current.addEventListener("scroll", handleScroll);
  
      return function cleanup() {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        (mobileViewActiveState == 1 && width <= 1024) && mobileSlider.current?.removeEventListener("scroll", handleScroll);
      };
    });
  
    const selectedStore = useSelector((state:any) => state.bookingEvent.selectedStore);
    const dispatch = useDispatch();
    return (<div className='mt-5'>
      <h2 className=' lg:text-2xl lgx:text-lg font-bold pl-4'>Please select a location</h2>
      {width <= 1024 && <div className="flex justify-around h-10 text-base leading-10 items-end mt-2">
        <div className={cx('border-b-2 cursor-pointer w-full text-center border-brand-grey500', { 'text-brand-orange !border-brand-orange': mobileViewActiveState == 0 })} onClick={() => setMobileViewActiveState(() => 0)}><span className="relative"><BulletedList className="relative inline-block -top-0.5" fill={mobileViewActiveState != 0 ? '#000000': '#ff8200'} /> List View</span></div>
        <div className={cx('border-b-2 cursor-pointer w-full text-center border-brand-grey500', { 'text-brand-orange !border-brand-orange': mobileViewActiveState == 1 })} onClick={() => setMobileViewActiveState(() => 1)}><span className="relative"><PinAlt className="inline-block w-4 -mt-1.5 mr-1" fill={mobileViewActiveState != 1 ? '#000000': '#ff8200'}/> Map View</span></div>
      </div>}
      <div className="flex lgx:flex-col">
        {(width > 1024 || (width <= 1024 && mobileViewActiveState == 0)) && <div className='min-w-[60%] px-4'>
          {/* <div className='h-10 mt-2 flex items-center justify-between'>
            <div className='flex-1 h-10 mt-2 relative'>
              <input className='border h-10 w-full border-brand-grey400 rounded ' style={{ 'textIndent': '12px' }} value={searchValue} onChange={handleSearchChange} placeholder='Search store' />
              <Search className='absolute right-0 top-0 mt-3 mr-2' />
            </div>
            <UIButton className='mt-1.5 lgx:hidden' size='small' > Show stores near me</UIButton> 
          </div> */}
          <div className="mt-4">
            {Object.keys(stores).map((city: any, i: number) => <div key={i}>
              {stores[city].filter((item: any) => { const founded = checkSearch(item); return founded }).length > 0 && <div className='text-2xl font-medium mb-2'>{city}</div>}
              {stores[city].filter((item: any) => {
                const founded = checkSearch(item);
  
                return founded
              }).map((store: any, idx: any) => <span key={idx} onMouseEnter={() => {
                setLat(() => parseFloat(store.lat));
                setLng(() => parseFloat(store.lng));
              }} onClick={() => {
                // if(width > 1024) {
                  dispatch(setSelectedStore(store))
                  dispatch(setAvailability(null))
                  setActivePage(() => 2)
                  router.push('#service')
                  bookingEvents.Booking1_SelectStore(store)
                // }
                setSelectedStoreIndex(() => store.id)
                
              }}><StoreLocationItem isSelected={selectedStore?.id == store.id} pos={psudoIdsByStoreId[store.id]} title={store.title} address={store.address1} storeId={store.id} >
                  {/* {(selectedStoreIndex == store.id) && <UIButton onClick={() => {
                    setCurrentState(() => Object.assign(currentState, { 'selectedStore': store }));
                    dispatch(setSelectedStore(store))
                    setActivePage(() => 2)
                    router.push('#service')
                    bookingEvents.Booking1_SelectStore(store)
                  }} className="lgx:px-2">Select Store</UIButton>} */}
                </StoreLocationItem>
              </span>)}
            </div>)}
            {onlyStores.filter((store:any, idx:any) => {
                const founded = checkSearch(store)
                return founded;
                }).length == 0 && <div className="text-center text-lg max-w-sm mx-auto text-brand-grey450"><StoreNotFound className="mx-auto mt-20" />We do not currently have a store located at the location you searched for.</div>}
          </div>
        </div>}
        <div className='relative'>
          {(width <= 1024 && mobileViewActiveState == 1) && <div className='top-[-200px] w-full z-10 pt-2 pb-2 flex overflow-hidden'>
            <div className='no-scrollbar overflow-x overflow-scroll overflow-y-hidden flex snap-x p-2' ref={mobileSlider} style={{ scrollSnapType: 'x mandatory', scrollPaddingLeft: '10px', scrollPaddingRight: '10px' }}>
              {onlyStores.map((item: any, idx: any) =>
                <div key={idx} className='flex justify-between items-center bg-white min-w-[calc(100%-80px)] p-4 mr-4 last-of-type:mr-0 border border-brand-grey500 rounded-lg min-h-[100px]' style={{ scrollSnapAlign: 'start' }}>
                  <div className='flex-col'>
                    <span className='font-medium text-base line-clamp-1'><span className='font-medium relative bg-brand-orange text-sm leading-[22px] text-white w-6 h-6 inline-block rounded-full text-center mr-1'>{psudoIdsByStoreId[item.id]}</span> {item.title}</span>
                    <span className=' leading-4 mt-1 text-brand-grey450 text-sm line-clamp-2 text-ellipsis'>{item.address1}</span>
                  </div>
                  <UIButton className='ml-2 whitespace-nowrap !p-2 m-0 py-1' size='small' onClick={() => {
                    //setCurrentState(() => Object.assign(currentState, { 'selectedStore': item }));
                    dispatch(setSelectedStore(item))
                    dispatch(setAvailability(null))
                    setActivePage(() => 2)
                    router.push('#service')
                    bookingEvents.Booking1_SelectStore(item)
                  }}>Select Store</UIButton>
                </div>)}
            </div>
          </div>}
        </div>
        {(width > 1024 || (width <= 1024 && mobileViewActiveState == 1)) && <div className='w-full lg:h-[500px] lgx:h-[600px] rounded-lg bg-brand-grey lgx:ml-0 m-4 mt-3 webkit-fill-current'>
          <LoadScript googleMapsApiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY as string}>
            <GoogleMap
              options={{ streetViewControl: true, zoomControl: true, fullscreenControl: false }}
              mapContainerStyle={{
                width: '100%',
                height: '100%'
              }}
              center={{
                lat: lat,
                lng: lng
              }}
              zoom={17}>
              {onlyStores.map((store: any, idx: any) => <Marker key={idx}
                icon={{
                  url: activeMarker == idx ? '/images/svgs/map_marker_orange.svg': '/images/svgs/map_marker_black.svg',
                }}
                label={{text:`${psudoIdsByStoreId[store.id]}`, color:'white', className:'text-base font-normal mb-2'}}
                onClick={() => handleActiveMarker(idx)}
                position={{
                  lat: parseFloat(store?.lat),
                  lng: parseFloat(store?.lng)
                }}
              >
                
                {activeMarker == idx && <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                  <div style={{ maxWidth: 200, fontSize: 14, margin:8}}>
                    <h3 style={{ fontWeight: 500, fontSize: 18 }}>{store?.title}</h3>
                    <p style={{ color: '#959595', marginTop: '8px' }}>{store?.address1}</p>
                    <div className="text-brand-blue mt-2 text-base font-medium" onClick={() => {
                      dispatch(setSelectedStore(store))
                      dispatch(setAvailability(null))
                      router.push('#service')
                      setActivePage(() => 2)
                      bookingEvents.Booking1_SelectStore(store)
                    }}>Select Store</div>
                  </div>
                </InfoWindow>}
              </Marker>)}
            </GoogleMap>
          </LoadScript>
        </div>}
      </div>
    </div>);
  }