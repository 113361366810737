import { SVGProps } from 'react';

const Edit = ({
  width = 24,
  height = 23,
  fill = '#FF8200',
  ...otherProps
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 23"
    {...otherProps}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.1574 3.48514C22.5901 3.91491 22.8326 4.50011 22.8305 5.10994C22.8323 5.71977 22.5898 6.30498 22.1573 6.73509L6.38209 22.5133C6.19651 22.6983 5.94495 22.8015 5.68284 22.8004L0.985106 22.8068C0.440953 22.8068 0 22.3662 0 21.822L0.00586414 17.1243C0.00615686 16.8633 0.109651 16.6133 0.294063 16.4288L16.0723 0.650583C16.5103 0.233086 17.0923 0 17.6977 0C18.3028 0 18.8845 0.233086 19.3228 0.650583L22.1574 3.48514ZM19.1124 6.9945L20.7645 5.34244C20.8258 5.28117 20.8596 5.19848 20.8599 5.11228C20.8605 5.0255 20.8262 4.94224 20.7649 4.88096L17.9298 2.04588C17.8679 1.98402 17.7844 1.94942 17.6973 1.94942C17.6099 1.94942 17.5264 1.98402 17.4648 2.04588L15.7958 3.71476L19.1124 6.9945ZM17.6981 8.4087L14.3815 5.12892L1.97476 17.5344L1.97095 20.8372L5.2737 20.8325L17.6981 8.4087Z"
      fill={fill}
    />
    <path
      d="M23.0031 21.9931C23.0031 22.5372 22.5622 22.9782 22.018 22.9782L10.9851 22.9702C10.441 22.9702 10 22.5293 10 21.9851C10 21.441 10.441 21 10.9851 21L22.018 21.0079C22.5622 21.0079 23.0031 21.4489 23.0031 21.9931Z"
      fill={fill}
    />
  </svg>
);
export default Edit;
