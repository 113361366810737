import CalendarAlt from "components/Icons/CalendarAlt";
import { useRouter } from "next/router";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Image from "next/legacy/image";
import { useReactToPrint } from "react-to-print";
import { format, addMinutes } from "date-fns";
import PinAlt from "components/Icons/PinAlt";
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import CheckmarkAlt from "components/Icons/CheckmarkAlt";
import UIButton from "components/Others/UIButton";
import { bookingEvents } from 'functions/bookingEvents';
import axios from "axios";
import { setBookingInfo, setBookingResponse, setSelectedProvider, setSelectedService, setSelectedStore, setSelectedTime } from "redux/features/booking/bookingSlice";
import CalendarCancel from "components/Icons/CalendarCancel";
import CalendarEdit from "components/Icons/CalendarEdit";
import { atcb_action } from "add-to-calendar-button";
import 'add-to-calendar-button/assets/css/atcb.css'
import CalendarAdd from "components/Icons/CalendarAdd";
import Print from "components/Icons/Print";
import Logo from 'components/Icons/Logo';

const MySwal = withReactContent(Swal)

export default function SuccessBooking({ setActivePage }: any){
    const componentRef = useRef(null);
    const router = useRouter();
    const dispatch =  useDispatch();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });
  
    const selectedStore = useSelector((state:any) => state?.bookingEvent.selectedStore);
    const selectedService = useSelector((state:any) => state?.bookingEvent.selectedService);
    const selectedProvider = useSelector((state:any) => state?.bookingEvent.selectedProvider);
    const bookingResponse = useSelector((state:any) => state?.bookingEvent.bookingResponse);
    const bookingInfo = useSelector((state:any) => state?.bookingEvent.bookingInfo);
    const selectedTime = useSelector((state:any) => state?.bookingEvent.selectedTime);

    const storeLink = selectedStore?.description?.map_url? selectedStore?.description?.map_url:`https://maps.google.com/?q=${selectedStore?.lat},${selectedStore?.lng}`
    
  
  
    return (<div className='mt-5 lgx:px-2' >
      <h2 className=' lg:text-2xl lgx:text-lg font-bold'>Thank you for your booking!</h2>
      <div className="lg:p-8 lgx:p-4 bg-[#f5f5f5] flex-1 mb-4 mt-4">
        <h1 className=" text-4xl font-bold">{selectedService?.name}</h1>
        {!(selectedService?.name) &&  <div className="w-full min-w-[230px] h-10 mt-2 animate-pulse rounded bg-brand-grey500"></div>}
        <div>
          <div className="flex lgx:flex-col mt-8 bg-white lg:h-48">
            <div className="bg-brand-orange text-md lgx:w-full lg:px-8 lg:h-48 items-center text-white text-center">
              <div className='pt-6 lgx:flex lgx:mb-8 lgx:mr-2'>
                <CalendarAlt className='lg:mx-auto lgx:m-2 mb-4'/>
                <div className='lgx:text-left text-xl'>{selectedTime && format(selectedTime, 'dd MMMM, EEEE')}
                  <div className="font-medium text-4xl lg:mt-2">{selectedTime && format(selectedTime, 'p')}</div>
                </div>
                {!selectedTime && <div className="w-full min-w-[230px] h-4 mt-2 animate-pulse rounded bg-brand-orange"></div>}
              </div>
            </div>
            <div className='p-8'>
              <div className='font-medium text-xl'>{bookingResponse?.provider} {!bookingResponse?.provider &&  <div className="w-full min-w-[230px] h-4 mt-2 animate-pulse rounded bg-brand-grey550"></div>}</div>
              <div>{selectedService?.price && `$${selectedService?.price} -`} {selectedService?.duration && selectedService?.duration+' mins'}</div>
              {!selectedService?.duration && <div className="w-full min-w-[230px] h-4 mt-2 animate-pulse rounded bg-brand-grey550"></div>}
              <div className='flex mt-4'><PinAlt className='mr-2 -mt-0.5 w-4' />{!selectedStore?.address2 && <div className="w-full min-w-[230px] h-4 mt-2 animate-pulse rounded bg-brand-grey550"></div>} <span className='font-medium'>{selectedStore?.address2}</span> </div>
              <div className=' text-brand-grey450'>{selectedStore?.address1 && selectedStore?.address1+','} <a className=" text-brand-blue text-sm font-medium cursor-pointer" rel="noreferrer" target="_blank" href={storeLink} >Show on Map</a></div>
            </div>
          </div>
  
          <div className="mt-10 bg-white p-[30px]">
            <div className=" text-2xl font-semibold">Before you come in</div>
            <div className="mt-2.5 mb-5 text-base">One of our team may ring you before your appoinment to confirm.</div>  
            <div className="text-base font-semibold mb-2.5">What to bring to your appoinment</div>
            <ul>
              <li className="flex text-sm mb-2.5"><CheckmarkAlt className=" mt-1 mr-2 min-w-[15px]" /> Your current glasses</li>
              <li className="flex text-sm mb-2.5"><CheckmarkAlt className=" mt-1 mr-2 min-w-[15px]" /> Your current contact lenses and their packaging if you wear them</li>
              <li className="flex text-sm mb-2.5"><CheckmarkAlt className=" mt-1 mr-2 min-w-[15px]" /> Your old prescription if you have it</li>
              <li className="flex text-sm"><CheckmarkAlt className=" mt-1 mr-2 min-w-[15px]" /> Any referrals you may have</li>
            </ul>
  
          </div>
  
          <div className='mt-10 flex lgx:flex-col justify-between max-w-sm'>
            <span className="text-brand-grey450">Full Patient Name : </span>
            <span className='w-52 text-left'>{bookingResponse?.client} {!bookingResponse?.client && <div className="w-full min-w-[230px] h-4 mt-2 animate-pulse rounded bg-brand-grey550"></div>}</span>
          </div>
          <div className='mt-4 flex lgx:flex-col justify-between max-w-sm'>
            <span className="text-brand-grey450">Patient Email : </span>
            <span className='w-52 text-left'>{bookingInfo?.client_email} {!bookingResponse?.client && <div className="w-full min-w-[230px] h-4 mt-2 animate-pulse rounded bg-brand-grey550"></div>}</span>
          </div>
          <div className='mt-4 flex lgx:flex-col justify-between max-w-sm'>
            <span className="text-brand-grey450">Patient phone : </span>
            <span className='w-52 text-left'>{bookingInfo?.client_phone} {!bookingResponse?.client && <div className="w-full min-w-[230px] h-4 mt-2 animate-pulse rounded bg-brand-grey550"></div>}</span>
          </div>
          <div className='mt-4 flex lgx:flex-col justify-between max-w-sm'>
            <span className="text-brand-grey450">Booking code : </span>
            <span className='w-52 text-left'>{bookingResponse?.code} {!bookingResponse?.client && <div className="w-full min-w-[230px] h-4 mt-2 animate-pulse rounded bg-brand-grey550"></div>}</span>
          </div>
          <div className='mt-8 lg:flex-col justify-between'>
            <div className='flex lgx:flex-wrap text-[#FF4F28]'><UIButton light={true} size={'small'} className="mr-2 mb-2 whitespace-nowrap text-brand-red" onClick={() => {
              
              MySwal.fire({
                title: "Cancel Booking",
                text: "Please confirm cancellation of this appointment.",
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Back',
                reverseButtons: true,
                buttonsStyling: false,
                customClass: {
                  confirmButton: 'btn bg-brand-black100 text-white m-3 pl-5 pr-5 text-base p-2.5 rounded-md ml-2',
                  cancelButton: 'btn m-3 rounded-md border border-brand-grey400 p-2.5 pl-5 pr-5'
                },
              }).then((result) => {
                if (result.isConfirmed) {
                  const requestBody: any = { 'code':bookingResponse?.code, 'region': router.locale }
                  axios.post(`${process.env.NEXT_PUBLIC_LARAVEL_URL}/bookings/cancel-booking`, requestBody, {
                    headers: {
                      'region': router.locale as string
                    }
                  }).then((res) => {
                    bookingEvents.BookingSuccess_Cancel();
                    //console.log("canceled booking ?")
                    //setCurrentState(() => Object.assign(currentState, { 'selectedService': null, 'selectedProvider': null, 'selectedStore': null,'bookingInfo': null, 'bookingResponse': null }));
                    dispatch(setSelectedProvider(null));
                    dispatch(setSelectedService(null));
                    dispatch(setSelectedStore(null));
                    dispatch(setBookingInfo(null))
                    dispatch(setBookingResponse(null));
                    dispatch(setSelectedTime(null));
                    MySwal.fire({
                      text: "Your booking has successfully cancelled.",
                      showCancelButton: false,
                      confirmButtonText: 'Close',
                      reverseButtons: true,
                      buttonsStyling: false,
                      customClass: {
                        confirmButton: 'btn bg-brand-black100 text-white m-3 pl-5 pr-5 text-base p-2.5 rounded-md ml-2',
                        cancelButton: 'btn m-3 rounded-md border border-brand-grey400 p-2.5 pl-5 pr-5'
                      },
                    })
                    //window.status = res.data;
                    setActivePage(() => 7)
                    //router.push('#store')
          
                  })
          
                } else if (
                  /* Read more about handling dismissals below */
                  result.dismiss === Swal.DismissReason.cancel
                ) {
                  /*Swal.fire(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                  )*/
                }
              }).then(() => {
          
              })
            }}><CalendarCancel className="inline-block mr-1 leading-10" /> Cancel</UIButton> 
            <UIButton light={true} size={'small'} onClick={() => {
              if(!selectedService?.id){
                setActivePage(() => 2)
              }else{
                setActivePage(() => 4)
              }
              bookingEvents.BookingSuccess_Reschedule();
            }} className="mb-2 mr-2 whitespace-nowrap"><CalendarEdit className="inline-block mr-1 leading-10" /> Reschedule</UIButton>
            <UIButton light={true} size={'small'} className="mb-2 mr-2 whitespace-nowrap " onClick={() => {
              bookingEvents.BookingSuccess_AddtoCalendar();
              const cal_end_date = selectedService?.duration ?
                      addMinutes(new Date(selectedTime),(parseInt(selectedService?.duration))):
                      addMinutes(new Date(selectedTime),15);
  
              const timezoneMap:any = {
                'international': 'Australia/Sydney',
                'au': 'Australia/Sydney',
                'nz': 'Pacific/Auckland',
                'ca': 'America/Toronto',
              }
              

              const atcb_data:any = {
                name: selectedService?.name,
                startDate: format(selectedTime, "yyyy-MM-dd"),
                endDate: format(selectedTime, "yyyy-MM-dd"),
                startTime:format(selectedTime, "HH:mm"),
                endTime:format(cal_end_date, "HH:mm"),
                options: ['Apple', 'Google', 'iCal', 'Microsoft365', 'Outlook.com', 'Yahoo'],
                iCalFileName: "Reminder-Event",
                location:selectedStore?.address2 + ' ' + selectedStore?.address1 + ' ' + selectedStore?.city,
                timeZone: selectedStore?.description?.tz ? selectedStore?.description?.tz: timezoneMap[router.locale as string]
              }
              atcb_action(atcb_data);
            }}> <CalendarAdd className="inline-block mr-1 leading-10" /> Add to Calendar </UIButton> 
            <UIButton light={true} size={'small'} className="mb-2 whitespace-nowrap " onClick={() => {
              //console.log(currentState)
              handlePrint()
              bookingEvents.BookingSuccess_Print();
              }}><Print className="inline-block mr-1 leading-10" /> Print</UIButton>
            </div>
  
            <div className='flex mt-2'>
              <UIButton className="" onClick={() => { 
                //console.log(currentState)
                //setCurrentState(() => {})
                //setCurrentState(() => Object.assign(currentState, { 'selectedService': null, 'selectedProvider': null, 'selectedStore': null,'bookingInfo': null, 'bookingResponse': null }));
                /*dispatch(setSelectedProvider(null));
                dispatch(setSelectedService(null));
                dispatch(setSelectedStore(null));
                dispatch(setBookingInfo(null))
                dispatch(setBookingResponse(null));
                dispatch(setSelectedTime(null));*/
                dispatch(setBookingResponse(null));
                setActivePage(() => 1) 
                router.push('#store')
                }}>Book more</UIButton></div>
          </div>
          
          
        </div>
        
      </div>
      {<div className="absolute left-[0] w-0 h-0 -z-10 overflow-hidden" id="printSection">
            <div className="min-w-[1000px] w-full p-20 relative bg-[#f5f5f5]" ref={componentRef}>
              <div className="w-60 mx-auto"><Logo height={75} width={250} fill="currentColor" /></div>
              <h1 className=" text-4xl font-bold mt-8">{selectedService?.name}</h1>
              <div className="w-full flex mt-8 bg-white h-48">
                <div className="bg-brand-orange text-md px-8 h-48 items-center text-white text-center">
                  <div className='pt-6 text-center'>
                    <CalendarAlt className='mx-auto mb-4' />
                    <div className='text-xl'>{selectedTime && format(selectedTime, 'dd MMMM, EEEE')}
                      <div className="font-medium text-4xl mt-2">{selectedTime && format(selectedTime, 'p')}</div>
                    </div>
  
                  </div>
                </div>
                <div className='p-8 flex-1'>
                  <div className='font-medium text-xl'>{bookingResponse?.provider}</div>
                  <div>{selectedService?.price && `$${selectedService?.price} -`} {selectedService?.duration} mins</div>
                  <div className='flex mt-4'><PinAlt className='mr-2 -mt-0.5 w-4' /> <span className='font-medium'>{selectedStore?.address2}</span> </div>
                  <div className=' text-brand-grey450'>{selectedStore?.address1}, {selectedStore?.city}</div>
                </div>
  
              </div>
              <div className="mb-8">
                <div className='mt-8 flex flex-col justify-between max-w-sm'>
                  <span className="text-brand-grey450">Full Patient Name : </span>
                  <span className='w-52 text-left'>{bookingResponse?.client}</span>
                </div>
                <div className='mt-4 flex flex-col justify-between max-w-sm'>
                  <span className="text-brand-grey450">Patient Email : </span>
                  <span className='w-52 text-left'>{bookingInfo?.client_email}</span>
                </div>
                <div className='mt-4 flex flex-col justify-between max-w-sm'>
                  <span className="text-brand-grey450">Patient phone : </span>
                  <span className='w-52 text-left'>{bookingInfo?.client_phone}</span>
                </div>
                <div className='mt-4 flex flex-col justify-between max-w-sm'>
                  <span className="text-brand-grey450">Booking code : </span>
                  <span className='w-52 text-left'>{bookingResponse?.code}</span>
                </div>
              </div>
              <div className='min-h-[500px] mt-2'>
                <Image width={1920} objectFit={'cover'} priority height={720} src={`https://maps.googleapis.com/maps/api/staticmap?center=${parseFloat(selectedStore?.lat)},${parseFloat(selectedStore?.lng)}&zoom=16&size=640x640&key=AIzaSyAtBHFy117A0qpK_S_9fo0xLzadRDrWlZc&markers=color:red%7Clabel:D%7C${parseFloat(selectedStore?.lat)},${parseFloat(selectedStore?.lng)}%7Csize:large&scale=2`} alt="map"></Image>
                  
                  {/* <LoadScript googleMapsApiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY as string}>
                    <GoogleMap
                      options={{ streetViewControl: false, zoomControl: false, fullscreenControl: false }}
                      mapContainerStyle={{
                        width: '100%',
                        height: '500px'
                      }}
                      center={{
                        lat: parseFloat(selectedStore?.lat),
                        lng: parseFloat(selectedStore?.lng)
                      }}
                      zoom={18}>
                      <Marker
                        icon={{
                          url: '/images/svgs/map_marker_orange.svg'
                        }}
                        position={{
                          lat: parseFloat(selectedStore?.lat),
                          lng: parseFloat(selectedStore?.lng)
                        }}
                      />
                    </GoogleMap>
                </LoadScript> */}
              </div>
            </div>
          </div>}
    </div>);
  } 
  