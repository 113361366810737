import { FC, useEffect, useState, useRef, createRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import cx from 'classnames';
import WeeklyCalendar from 'components/WeeklyCalendar/WeeklyCalendar';
import router, { useRouter } from 'next/router';
import { setSelectedTime, setBookingResponse, setBookingInfo } from 'redux/features/booking/bookingSlice';
import { format, parse } from "date-fns";
import UIButton from 'components/Others/UIButton';
import ProviderIndicator from './Indicators/ProviderIndicator';
import ServiceIndicator from './Indicators/ServiceIndicator';
import StoreIndicator from './Indicators/StoreIndicator';
import { bookingEvents } from 'functions/bookingEvents';

export default function TimeSelection({ setActivePage, providers }: any){
  

    const [data, setData] = useState<any>(null);
    const [calendarData, setCalendarData] = useState<any>(null);
    const [offdays, setOffDays] = useState<any>([]);
    const [avaliableTimes, setAvailableTimes] = useState<any>([]);
    const [firstWorkingDay, setFirstWorkingDay] = useState<any>(null);
    const [calendarKey, setCalendarKey] = useState(0);
    const router = useRouter();
    const [rescheduleSelection, setRescheduleSelection] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const selectedProvider = useSelector((state:any) => state.bookingEvent.selectedProvider);
    const selectedService = useSelector((state:any) => state.bookingEvent.selectedService);
    const selectedStore = useSelector((state:any) => state.bookingEvent.selectedStore);
    const bookingResponse = useSelector((state:any) => state.bookingEvent.bookingResponse);
    const selectedTime = useSelector((state:any) => state.bookingEvent.selectedTime);
    const hasBookingCode = useMemo(() => bookingResponse?.code, [bookingResponse?.code]);
  
    const showDetailsHandle = (day: any) => {
      setData(() => day);
      setAvailableTimes(() => []);
      setRescheduleSelection(() => null)
  
      var url = `${process.env.NEXT_PUBLIC_LARAVEL_URL}/bookings/daily-availability?date=${format(day, 'yyyy-MM-dd')}&provider_id=${selectedProvider?.id}&service_id=${selectedService?.id}`;
      if(selectedProvider?.id == 0){
        providers.map((item: any) => {
          if(selectedService.providers.includes(parseInt(item.id)) && selectedStore.providers.includes(item.id)){
            url += `&provider_ids[]=${item.id}`
          }
        })
      }

      axios.get(url, {
        headers: {
          'region': router.locale as string
        }
      }).then((res) => {
        
        setAvailableTimes(() => res.data.data.startTimeMatrix)
      });
    };
  
  
    useEffect(() => {
      const endDate = new Date();
      endDate.setDate(endDate.getDate() + 60);
  
      if (calendarData?.dayOffDays && calendarData?.startTimeMatrix) {
        setOffDays(() => calendarData.dayOffDays)
        //console.log("offdays", calendarData.dayOffDays)
        setAvailableTimes(() => [...calendarData.startTimeMatrix])
        setFirstWorkingDay(() => calendarData.firstWorkingDay)
        setCalendarKey(() => calendarKey + 1)
      } else {
        setFirstWorkingDay(() => format(new Date(), 'yyyy-MM-dd'))
        setOffDays(() => [])
        setAvailableTimes(() => [])
        setCalendarKey(() => calendarKey + 1)
      }
  
  
      return () => {
        // weekChangeHandle(new Date(), endDate)
        //showDetailsHandle(new Date())
      }
    }, [calendarData])
  
    useEffect(() => {
      if (!(selectedStore?.id || selectedProvider?.id < 0)) return;

      let url = `${process.env.NEXT_PUBLIC_LARAVEL_URL}/bookings/first-calendar-data?provider_id=${selectedProvider?.id}&service_id=${selectedService?.id}`
      if(selectedProvider?.id == 0){
        providers.map((item: any) => {
          if(selectedService.providers.includes(parseInt(item.id)) && selectedStore.providers.includes(item.id)){
            url += `&provider_ids[]=${item.id}`
          }
        })
      }
      setCalendarData(() => []);
      
      axios.get(url, {
          headers: {
            'region': router.locale as string
          }
        }).then((res) => {
          setCalendarData(() => res.data.data)
      });
  
    }, [selectedStore, selectedProvider]);
  
   
    const bookingInfo = useSelector((state:any) => state.bookingEvent.bookingInfo);
    const dispatch = useDispatch();
  
    return (<div className='mt-5 lgx:px-2'>
      <h2 className=' lg:text-2xl lgx:text-lg font-bold'>Please select a date & time</h2>
      <div className="flex justify-between lgx:flex-col">
        <div className='max-w-[70%] lgx:max-w-full lgx:mb-4'>
          <div className='mt-4 p-5 border border-brand-grey400 rounded-xl select-none'>
            <WeeklyCalendar key={calendarKey} showDetailsHandle={showDetailsHandle} offDays={offdays} firstWorkingDay={firstWorkingDay} providers={providers}/>
            <div className="lg:mt-10">
  
            </div>
            <div className="relative max-w-xl mx-auto mb-10">
              {<div className="lg:mt-2 lgx:mt-4 mx-auto flex flex-wrap justify-left">{avaliableTimes.map((item: any, idx: any) => <div onClick={() => {
                const date = new Date(data || parse(firstWorkingDay,"yyyy-MM-dd", new Date())).setHours(item.split(':')[0], item.split(':')[1], 0, 0)
                if(!hasBookingCode){
                  //setCurrentState(() => Object.assign(currentState, { 'selectedTime': new Date(data || firstWorkingDay).setHours(item.split(':')[0], item.split(':')[1], 0, 0) }))
                  dispatch(setSelectedTime(date))
                  bookingEvents.Booking3_SelectTime(selectedProvider, selectedService, selectedStore, date);
                  setActivePage(() => 5)
                  router.push('#customer')
                }else{
                  //setCurrentState(() => Object.assign(currentState, { 'selectedTime': new Date(data || firstWorkingDay).setHours(item.split(':')[0], item.split(':')[1], 0, 0) }))
                  bookingEvents.Booking3_SelectTime(selectedProvider, selectedService, selectedStore, date);
                  dispatch(setSelectedTime(date))
                  setRescheduleSelection(() => idx)
                }
              }} className={cx('cursor-pointer bg-brand-blue25 text-white hover:bg-[#80D6E3] select-none inline-block min-w-[80px] w-24 h-10 text-center lg:m-2 lgx:m-1 p-2 lg:pl-2 lg:pr-2 rounded-full whitespace-nowrap', {'!bg-brand-orange': rescheduleSelection === idx})} key={idx}>{format(new Date(data || firstWorkingDay).setHours(item.split(':')[0], item.split(':')[1], 0, 0), "p")}</div>)}</div>}
              {avaliableTimes.length == 0 && <div className="flex flex-wrap justify-left items-start mx-auto">
                <div className='animate-pulse bg-brand-blue25 opacity-80 w-24 h-10 text-white select-none inline-block lg:m-2 lgx:m-1 p-2 lg:pl-4 lg:pr-4 rounded-full'></div>
                <div className='animate-pulse bg-brand-blue25 opacity-80 w-24 h-10 text-white select-none inline-block lg:m-2 lgx:m-1 p-2 lg:pl-4 lg:pr-4 rounded-full'></div>
                <div className='animate-pulse bg-brand-blue25 opacity-80 w-24 h-10 text-white select-none inline-block lg:m-2 lgx:m-1 p-2 lg:pl-4 lg:pr-4 rounded-full'></div>
                <div className='animate-pulse bg-brand-blue25 opacity-80 w-24 h-10 text-white select-none inline-block lg:m-2 lgx:m-1 p-2 lg:pl-4 lg:pr-4 rounded-full'></div>
                <div className='animate-pulse bg-brand-blue25 opacity-80 w-24 h-10 text-white select-none inline-block lg:m-2 lgx:m-1 p-2 lg:pl-4 lg:pr-4 rounded-full'></div>
              </div>}
            </div>
            
            {hasBookingCode && <div className='lg:mt-8 lgx:pt-4 lg:pt-8 border-t border-brand-grey500 text-sm text-center'>
  
              <UIButton loading={isSubmitting} disabled={rescheduleSelection==null} className='mx-auto mb-4' onClick={() => {
                const requestBody = Object.assign({},bookingInfo, {"start_datetime": format(selectedTime, 'yyyy-MM-dd HH:mm:ss'), "booking_code":bookingResponse?.code});
                let providerIds:any = [];
                if(selectedStore?.id){
                  providers.map((item: any) => {
                    if(selectedService.providers.includes(parseInt(item.id)) && selectedStore.providers.includes(item.id)){
                      providerIds.push(item.id)
                    }
                  })
                }
                bookingEvents.Booking3_SelectTime(selectedProvider, selectedService, selectedStore, selectedTime);
                const latestSelection = Object.assign(requestBody, {
                  "location_id": selectedStore?.id,
                  "service_id": selectedService?.id,
                  "provider_id": selectedProvider?.id,
                })

                if(selectedProvider?.id == 0 ){
                  latestSelection.provider_ids = providerIds
                }
                setIsSubmitting(() => true)
                
                axios.post(`${process.env.NEXT_PUBLIC_LARAVEL_URL}/bookings/edit-booking`, latestSelection, {
                  headers: {
                    'region': router.locale as string
                  }
                }).then((res) => {
                  //console.log("edit booking");
                  //console.log(res)
                  //setCurrentState(() => Object.assign({}, currentState, { 'bookingResponse': res.data.data }, { 'bookingInfo': requestBody }))
                  
                  dispatch(setBookingResponse(res.data.data));
                  dispatch(setBookingInfo(latestSelection));
                  setActivePage(() => 6)
                  router.push('#confirm')
                }).finally(() => {
                  setIsSubmitting(() => false)
                })
            }}>Reschedule Booking</UIButton></div>}
            <div className='lg:mt-8 lgx:pt-4 lg:pt-8 border-t border-brand-grey500 text-sm'>
              {(router.locale != 'ca' && router.locale != 'au') && <div className='text-center lgx:text-xs'>Can’t find date or time that suits you? <span onClick={() => setActivePage(() => 1)} className='cursor-pointer text-brand-blue'>Check out our other stores.</span></div> }
              <div className='text-center mt-4 mb-4 lgx:text-xs'>
                {router.locale == 'au' && <>If you are unable to find a suitable time or have any special requests, please reach out<br/> to us at <a href="tel:0253003003" className='cursor-pointer text-brand-blue'>(02) 5300 3003</a> or send us an email at <a className='cursor-pointer text-brand-blue' href="mailto:hello@dresden.vision" >hello@dresden.vision</a>.</>}
                {router.locale == 'ca' && <>If you are unable to find a suitable time or have any special requests, please reach out<br/> to us at <a href="tel:6474244288 " className='cursor-pointer text-brand-blue'>(647) 424 4288</a> or send us an email at <a className='cursor-pointer text-brand-blue' href="mailto:hello@ca.dresden.vision" >hello@ca.dresden.vision</a>.</>}
              </div>
            </div>
  
          </div>
        </div>
        <div className="w-full h-full relative mt-4"> 
          <ProviderIndicator setActivePage={setActivePage} />
          <ServiceIndicator setActivePage={setActivePage} />
          <StoreIndicator setActivePage={setActivePage} />
        </div>
      </div>
    </div>);
  }
  