/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import cx from 'classnames';
import {
  format,
  parse,
  startOfWeek,
  addDays,
  isSameDay,
  lastDayOfWeek,
  addMonths,
  isFirstDayOfMonth
} from "date-fns";
import Caret from "components/Icons/Caret";
import { getOrdinalNum } from "utils/common.utils";
import axios from 'axios';
import { useRouter } from "next/router";
import useWindowSize from "hooks/useWindowSize";
import { useSelector } from "react-redux";
import { bookingEvents } from 'functions/bookingEvents';

const Calendar = ({ showDetailsHandle, offDays, firstWorkingDay, providers }: any) => {
  
  const [currentMonth] = useState(new Date());
  
  // const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
  const [selectedDate, setSelectedDate] = useState(firstWorkingDay?new Date(parse(firstWorkingDay, "yyyy-MM-dd", new Date)):new Date());
  const [offdays, setOffDays] = useState(offDays);
  const slider:any = useRef(null);
  const timespan = 14;
  const [spanMultiplier, setSpanMultiplier] = useState(1);
  const router = useRouter();
  const {width} = useWindowSize()
  const [isRequesting, setIsRequesting] = useState(false)

  const selectedProvider = useSelector((state:any) => state.bookingEvent.selectedProvider);
  const selectedService = useSelector((state:any) => state.bookingEvent.selectedService);
  const selectedStore = useSelector((state:any) => state.bookingEvent.selectedStore);

  useEffect(() => {
    function handleScroll(evt:any) {
      /*console.log(evt.target.scrollLeft);
      console.log(evt.target.clientWidth);
      console.log(evt.target.scrollWidth);*/
      if (Math.ceil(evt.target.scrollLeft + evt.target.clientWidth) >= evt.target.scrollWidth-20) {
        const latestScrollPosition = evt.target.scrollLeft - 20;
        
        const countryTreshold = router.locale == 'au' ? 6 : 24
       
        const startDate = new Date();
        /*slider.current.scroll({
          latestScrollPosition
        });*/
        startDate.setDate(startDate.getDate() + (timespan*spanMultiplier));
        const endDate = addDays(startDate, ((timespan*spanMultiplier)+7));
        let url:any = `${process.env.NEXT_PUBLIC_LARAVEL_URL}/bookings/dayoff-list?start_date=${format(startDate, 'yyyy-MM-dd')}&end_date=${format(endDate,'yyyy-MM-dd')}&provider_id=${selectedProvider.id}&service_id=${selectedService.id}`;

        if(selectedProvider.id === 0){
          providers.map((item: any) => {
            if(selectedService.providers.includes(parseInt(item.id)) && selectedStore.providers.includes(item.id)){
              url+=`&provider_ids[]=${item.id}`;
            }
          });
        }
        if(spanMultiplier < countryTreshold){ // ~ 1 year
          setSpanMultiplier(() => spanMultiplier+1);
        }

        setIsRequesting(() => true)
        axios.get(url, {headers:{'region':router.locale as string}}).then((res)=>{
          setOffDays([...offdays, ...res.data.data.dayOffDays]);
          setIsRequesting(() => false)
        })
        
      }
      //console.log(evt.target.scrollLeft);
    }

    slider.current.addEventListener("scroll", handleScroll);

    return function cleanup() {
      slider.current?.removeEventListener("scroll", handleScroll);
    };
  });

  // const changeMonthHandle = (btnType:any) => {
  //   if (btnType === "prev") {
  //     setCurrentMonth(subMonths(currentMonth, 1));
  //   }
  //   if (btnType === "next") {
  //     setCurrentMonth(addMonths(currentMonth, 1));
  //   }
  // };

  const changeWeekHandle = (btnType:any) => {
    
    //console.log("current week", currentWeek);
    /*if (btnType === "prev") {
      //console.log(subWeeks(currentMonth, 1));
      setCurrentMonth(subWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
      weekChangeHandle(startOfWeek(subWeeks(currentMonth, 1), { weekStartsOn: 1 }), lastDayOfWeek(subWeeks(currentMonth, 1), { weekStartsOn: 1 }))
    }
    if (btnType === "next") {
      //console.log(addWeeks(currentMonth, 1));
      setCurrentMonth(addWeeks(currentMonth, 1));
      setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
      weekChangeHandle(startOfWeek(addWeeks(currentMonth, 1), { weekStartsOn: 1 }), lastDayOfWeek(addWeeks(currentMonth, 1), { weekStartsOn: 1 }))
    }*/
    let left;
    const { scrollLeft, clientWidth } = slider.current;

    switch (btnType) {
      case "prev":
        left = scrollLeft - clientWidth;
        break;
      case "next":
      default:
        left = scrollLeft + clientWidth;
        break;
    }
    if(slider){
      slider.current.scroll({
        left,
        behavior: "smooth"
      });
    }
    
      
    
  };

  const onDateClickHandle = (day:any) => {
    setSelectedDate(day);
    showDetailsHandle(day);
  };

  // const renderHeader = () => {
  //   const dateFormat = "MMM yyyy";
  //   // console.log("selected day", selectedDate);
  //   return (
  //     <div className="header row flex-middle">
  //       <div className="col col-start">
  //         {/* <div className="icon" onClick={() => changeMonthHandle("prev")}>
  //           prev month
  //         </div> */}
  //       </div>
  //       <div className="col col-center">
  //         <span>{format(currentMonth, dateFormat)}</span>
  //       </div>
  //       <div className="col col-end">
  //         {/* <div className="icon" onClick={() => changeMonthHandle("next")}>next month</div> */}
  //       </div>
  //     </div>
  //   );
  // };
  // const renderDays = () => {
  //   const dateFormat = "EEE";
  //   const days = [];
  //   const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
  //   for (let i = 0; i < 7; i++) {
  //     days.push(
  //       <div className="w-10 text-center m-2 col-center" key={i}>
  //         {format(addDays(startDate, i), dateFormat)}
  //       </div>
  //     );
  //   }
  //   return <div className="days flex justify-between">{days}</div>;
  // };
  const renderCells = () => {
    const workingDay = parse(firstWorkingDay, "yyyy-MM-dd", new Date()).toJSON();
    
    const startDate = new Date(workingDay);
    //console.log("start date", startDate);
    
    const endDate = addDays(startDate, timespan);
    const dateFormat = "d";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";
    const fwd = new Date();
    // console.log("=============>", router.locale)
    const daysLength = spanMultiplier <= 23 ? (timespan*spanMultiplier) : 366; // 366 is the max number of days in a year
    while (day <= endDate) {
      for (let i = 0; i < daysLength; i++) { 
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        
        days.push(
          <span key={'_' + day} className="flex">
            {isFirstDayOfMonth(day) && offdays.length != 0 && <div className="flex relative z-10 h-5 -right-5 lgx:-right-3 mx-auto select-none text-center rounded-full w-1 items-end -top-5">
            {isFirstDayOfMonth(day) && offdays.length == 0 && <div className="sticky justify-end mx-auto left-0 text-center rounded-full lg:min-w-[64px] lg:w-16 lg:h-16 lgx:h-14 lgx:w-14 lg:m-2 lg:ml-3 lg:mr-3 lgx:ml-1 lgx:mr-1 lg:pl-1 lg:pr-1 animate-pulse"></div>}  
              <div className="text-lg mr-1 font-medium">{format(day, 'MMMM')}</div>
              <span className="number text-base font-normal relative">{format(day, 'YYY')}</span>
            </div>}
            {offdays.length == 0 && <div  className="flex-col relative mx-auto text-center border-[2px] border-brand-grey650 bg-brand-grey650 rounded-full lg:min-w-[64px] lg:w-16 lg:h-16 lgx:h-14 lgx:w-14 lg:m-2 lg:ml-3 lg:mr-3 lgx:ml-1 lgx:mr-1 lg:pl-1 lg:pr-1 animate-pulse"></div>}
            {offdays.length != 0 &&
              <div
                style={{ scrollSnapAlign: 'center' }}
                className={cx(
                  'flex-col relative mx-auto text-center select-none border-[2px] border-brand-grey650 bg-brand-grey650 rounded-full lg:min-w-[64px] lg:w-16 lg:h-16 lgx:h-14 lgx:w-14 lg:m-2 lg:ml-3 lg:mr-3 lgx:ml-1 lgx:mr-1 lg:pl-1 lg:pr-1 cell',
                  { 'bg-brand-grey400 cursor-default text-brand-grey25': (new Date(day) < new Date(workingDay) && !isSameDay(day, new Date(workingDay))) },
                  { 'bg-brand-grey650 text-black': isSameDay(day, fwd) },
                  { 'border-[2px] !border-brand-orange !bg-brand-orange !text-white  ': isSameDay(day, selectedDate) && !offdays.includes(format(day, "yyyy-MM-dd")) },
                  { 'offday bg-brand-grey650 text-brand-grey500': offdays.includes(format(day, "yyyy-MM-dd"))  },
                  { 'cursor-pointer hover:bg-brand-grey500': !offdays.includes(format(day, "yyyy-MM-dd"))  }
                )}
                onClick={() => {
                  if (offdays.includes(format(cloneDay, 'yyyy-MM-dd'))) return
                  if (isRequesting) return
                  // dont clickable if workingDays before
                  if (new Date(cloneDay).valueOf() < new Date(workingDay).valueOf() && !isSameDay(cloneDay, new Date(workingDay))) return
                  onDateClickHandle(cloneDay);
                }}
              >
                <div className="text-sm lgx:text-xs mt-2 mb-1">{format(cloneDay, "EEE")}.</div>
                <span className="number text-xl font-medium relative -top-2">{formattedDate}</span>

              </div>}
            
          </span>);
        day = addDays(day, 1);
      }

      
    }

    rows.push(
      <div key={'week_'+endDate} className="flex lgx:flex-col justify-between ">
        <div className="cursor-pointer lgx:mt-4 lgx:flex" onClick={() => changeWeekHandle("prev")}>
          <div className="icon w-10 h-10 lg:pt-5">
            <Caret className="mx-auto lg:mt-9 rotate-90" fill="black" />
          </div>
          {width < 1024 && <div className="-mt-2 -ml-1">Prev</div>}
        </div>
        <div className="text-center lgx:order-2 flex-1 max-w-fit pl-1 pr-1 mr-1 ml-1 overflow-x-hidden justify-between">
          <div ref={slider} className="no-scrollbar flex justify-between max-w-fit overflow-y-hidden overflow-x-auto pt-6" style={{scrollSnapType: 'x mandatory'}} >
            {days}
          </div>
        </div>
        <div className="cursor-pointer lgx:mt-4 lgx:order-1 lgx:absolute lgx:right-8 lgx:flex lgx:flex-row-reverse" onClick={() => changeWeekHandle("next")}>
          <div className="icon w-10 h-10 lg:pt-5"><Caret className="mx-auto lg:mt-9 -rotate-90" fill="black" /></div>
          {width < 1024 && <div className="-mt-2 -mr-1">Next</div>}
        </div>
      </div>
    );
    days = [];
    return <div className="body">{rows}</div>;
  };
  const renderFooter = () => {
    const dateFormat = "dd MMMM yyyy";
    const options:any = {
      weekday: "long",
      formatMatcher: "basic",
    };
    const formattedDate = selectedDate.toLocaleDateString("en-US", options);
    
    return (
      <div className="header flex items-center justify-between border-t-[1px] border-brand-grey500 mt-8">
        {offdays.length != 0 && <div className="mx-auto lg:mt-8 lgx:mt-2 lgx:text-xl text-2xl">{formattedDate}, {format(selectedDate, dateFormat)}</div>}
        {offdays.length == 0 && <div className="mx-auto lg:mt-12 lgx:mt-4 text-2xl w-80 h-4 bg-brand-grey200 rounded-full animate-pulse dark:bg-gray-700"></div>}
      </div>
    );
  };
  return (
    <div className="calendar">
      {/*renderDays()*/}
      {renderCells()}
      {renderFooter()}
    </div>
  );
};

export default Calendar;
/**
 * Header:
 * icon for switching to the previous month,
 * formatted date showing current month and year,
 * another icon for switching to next month
 * icons should also handle onClick events to change a month
 */
