
import { SVGProps } from 'react';

const CalendarAdd = ({
  width = 22,
  height = 18,
  fill = 'black',
  ...otherProps
}: SVGProps<SVGSVGElement>) => (
    <svg {...otherProps} width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.25 19.75V16.75H11.25V15.25H14.25V12.25H15.75V15.25H18.75V16.75H15.75V19.75H14.25ZM2.3 17.5C1.8 17.5 1.375 17.325 1.025 16.975C0.675 16.625 0.5 16.2 0.5 15.7V4.3C0.5 3.8 0.675 3.375 1.025 3.025C1.375 2.675 1.8 2.5 2.3 2.5H3.7V0.375H5.225V2.5H10.8V0.375H12.3V2.5H13.7C14.2 2.5 14.625 2.675 14.975 3.025C15.325 3.375 15.5 3.8 15.5 4.3V10.225C15.25 10.1917 15 10.175 14.75 10.175C14.5 10.175 14.25 10.1917 14 10.225V8.3H2V15.7C2 15.7667 2.03333 15.8333 2.1 15.9C2.16667 15.9667 2.23333 16 2.3 16H9.15C9.15 16.25 9.16267 16.5 9.188 16.75C9.21267 17 9.26667 17.25 9.35 17.5H2.3ZM2 6.8H14V4.3C14 4.23333 13.9667 4.16667 13.9 4.1C13.8333 4.03333 13.7667 4 13.7 4H2.3C2.23333 4 2.16667 4.03333 2.1 4.1C2.03333 4.16667 2 4.23333 2 4.3V6.8ZM2 6.8V4V6.8Z" fill={fill} />
    </svg>
);

export default CalendarAdd;
