import { SVGProps } from 'react';

const ServiceAlt = ({
    width = 24,
    height = 24,
    fill = '#FF8200',
    ...otherProps
}: SVGProps<SVGSVGElement>) => (
    <svg {...otherProps} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M6.5 4.5H8C8 5.60457 8.89543 6.5 10 6.5H14C15.1046 6.5 16 5.60457 16 4.5H17.5C18.0523 4.5 18.5 4.94772 18.5 5.5V19.5C18.5 20.0523 18.0523 20.5 17.5 20.5H6.5C5.94772 20.5 5.5 20.0523 5.5 19.5V5.5C5.5 4.94772 5.94772 4.5 6.5 4.5ZM18 3H15.7324C15.3866 2.4022 14.7403 2 14 2H10C9.25972 2 8.61337 2.4022 8.26756 3H6C4.89543 3 4 3.89543 4 5V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V5C20 3.89543 19.1046 3 18 3ZM8 11.75C8 11.3358 8.33579 11 8.75 11H15.25C15.6642 11 16 11.3358 16 11.75C16 12.1642 15.6642 12.5 15.25 12.5H8.75C8.33579 12.5 8 12.1642 8 11.75ZM8.75 15C8.33579 15 8 15.3358 8 15.75C8 16.1642 8.33579 16.5 8.75 16.5H15.25C15.6642 16.5 16 16.1642 16 15.75C16 15.3358 15.6642 15 15.25 15H8.75ZM10.25 3.5C9.83579 3.5 9.5 3.83579 9.5 4.25C9.5 4.66421 9.83579 5 10.25 5H13.75C14.1642 5 14.5 4.66421 14.5 4.25C14.5 3.83579 14.1642 3.5 13.75 3.5H10.25Z" fill="#FF8200" />
    </svg>
);

export default ServiceAlt;


