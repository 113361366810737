import { useDispatch, useSelector } from 'react-redux';
import router, { useRouter } from 'next/router';
import ServiceAlt from 'components/Icons/ServiceAlt';
import Clock from 'components/Icons/Clock';
import TagAlt from 'components/Icons/TagAlt';
import Edit from 'components/Icons/Edit';

export default function ServiceIndicator({ setActivePage }: any) {

    const selectedService = useSelector((state: any) => state?.bookingEvent.selectedService);
    return (<div className="relative lg:ml-4 items-end"><div className="bg-brand-grey650 mb-4 relative rounded-[8px]">
        <div className="p-4 pb-2 flex items-center">
            <ServiceAlt className="w-6 mt-0 mr-2" /><div className="flex-1 font-medium text-lg"> Appointment Type </div><Edit onClick={() => { setActivePage(() => 2); router.push('#service'); }} fill={"black"} className="mt-1 mr-1 w-6 cursor-pointer" />
        </div>
        <div className="p-4 pt-0">
            <div className="mb-2">{selectedService?.name}</div>
            <div className=" text-brand-grey450 text-sm flex items-center mt-2">
                {selectedService?.price && <div className='mr-4 flex leading-6'><TagAlt className="mt-1 mr-1" /> ${selectedService?.price}</div>}
                <Clock className="mr-1" /> {selectedService?.duration} mins
            </div>
        </div>
    </div></div>);
}
