import { useDispatch, useSelector } from 'react-redux';
import router, { useRouter } from 'next/router';
import Edit from 'components/Icons/Edit';
import Optometrist from 'components/Icons/Optometrist';

export default function ProviderIndicator({ setActivePage }: any) {
    const selectedProvider = useSelector((state: any) => state.bookingEvent.selectedProvider);
    return (<div className="relative lg:ml-4 items-end"><div className="bg-brand-grey650 mb-4 relative rounded-[8px]">
        <div className="p-4 pb-2 flex items-center">
            <Optometrist className="w-6 mt-0 mr-2" /><div className="flex-1 font-medium text-lg"> Provider </div><Edit onClick={() => {
                setActivePage(() => 3)
                router.push('#provider')
            }} fill={"black"} className="mt-1 mr-1 w-6 cursor-pointer" />
        </div>
        <div className="p-4 pt-0">
            <div className="mb-2">{selectedProvider?.name}</div>
        </div>
    </div></div>);
}
