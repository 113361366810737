import * as React from 'react';

const TagAlt = ({
  fill = '#959595',
  ...otherProps
}: React.SVGProps<SVGSVGElement>) => (
  
  <svg {...otherProps} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.875 16.7295C10.5277 17.0628 10.118 17.2295 9.646 17.2295C9.174 17.2295 8.76433 17.0628 8.417 16.7295L1.271 9.58348C1.11833 9.41682 0.996663 9.22582 0.905996 9.01048C0.815996 8.79582 0.770996 8.57715 0.770996 8.35448V2.50048C0.770996 2.02848 0.940996 1.62215 1.281 1.28148C1.62166 0.941484 2.028 0.771484 2.5 0.771484H8.354C8.59 0.771484 8.80866 0.813151 9.01 0.896484C9.21133 0.979818 9.39533 1.10482 9.562 1.27148L16.729 8.41748C17.0623 8.75082 17.229 9.15348 17.229 9.62548C17.229 10.0975 17.0623 10.5072 16.729 10.8545L10.875 16.7295ZM9.646 15.5005L15.5 9.64648L8.354 2.50048H2.5V8.35448L9.646 15.5005ZM4.5 5.75048C4.84733 5.75048 5.14233 5.62882 5.385 5.38548C5.62833 5.14282 5.75 4.84782 5.75 4.50048C5.75 4.15315 5.62833 3.85815 5.385 3.61548C5.14233 3.37215 4.84733 3.25048 4.5 3.25048C4.15266 3.25048 3.85766 3.37215 3.615 3.61548C3.37166 3.85815 3.25 4.15315 3.25 4.50048C3.25 4.84782 3.37166 5.14282 3.615 5.38548C3.85766 5.62882 4.15266 5.75048 4.5 5.75048Z" fill={fill} />
  </svg>

);

export default TagAlt;
