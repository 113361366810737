import * as React from 'react';

const Optometrist = ({
    fill = '#878B87',
    ...otherProps
}: React.SVGProps<SVGSVGElement>) => (

    <svg {...otherProps} width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M12.9999 9.00043C12.1643 9.62808 11.1256 10 10 10C8.8744 10 7.83568 9.62806 7.00002 9.00038L6.86877 9.00038C6.89936 8.9903 6.93002 8.98037 6.96075 8.97058C5.76865 8.05672 5 6.61813 5 5C5 2.23858 7.23858 0 10 0C12.7614 0 15 2.23858 15 5C15 6.61813 14.2313 8.05672 13.0393 8.97058C13.07 8.98038 13.1007 8.99033 13.1314 9.00043L12.9999 9.00043ZM13.5 5C13.5 6.933 11.933 8.5 10 8.5C8.067 8.5 6.5 6.933 6.5 5C6.5 3.067 8.067 1.5 10 1.5C11.933 1.5 13.5 3.067 13.5 5ZM5.67923 9.47943C2.31964 11.0915 0 14.525 0 18.5004V21.5004H20V18.5004C20 14.5251 17.6805 11.0917 14.321 9.47956L13.1838 10.6167L13.1916 10.6199L10.0123 13.7992L6.15161 9.93848L6.14494 9.94515L5.67923 9.47943ZM10.75 15.1486L14.5678 11.3308C16.9322 12.8403 18.5 15.4872 18.5 18.5004V20.0004H10.75V15.1486ZM9.25 15.1311V20.0004H1.5V18.5004C1.5 15.4827 3.07254 12.8323 5.44285 11.324L9.25 15.1311ZM13.25 15.5C12.8358 15.5 12.5 15.8358 12.5 16.25C12.5 16.6642 12.8358 17 13.25 17H15.75C16.1642 17 16.5 16.6642 16.5 16.25C16.5 15.8358 16.1642 15.5 15.75 15.5H13.25Z" fill="#FF8200" />
    </svg>

);

export default Optometrist;

