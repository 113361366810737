

import { SVGProps } from 'react';

const Share = ({
    ...otherProps
}: SVGProps<SVGSVGElement>) => (
    <svg {...otherProps} width="306" height="153" viewBox="0 0 306 153" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="306" height="153" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M231.618 103.04C242.73 89.8595 242.079 70.1366 229.665 57.7224C216.564 44.6212 195.323 44.6212 182.221 57.7224C169.12 70.8237 169.12 92.065 182.221 105.166C194.664 117.609 214.449 118.234 227.63 107.043L252.681 132.093C253.784 133.196 255.573 133.196 256.676 132.093C257.779 130.99 257.779 129.201 256.676 128.098L231.618 103.04ZM226.073 61.3155C237.189 72.4323 237.189 90.4563 226.073 101.573C214.956 112.69 196.932 112.69 185.815 101.573C174.698 90.4563 174.698 72.4323 185.815 61.3155C196.932 50.1986 214.956 50.1986 226.073 61.3155Z" fill="#FFC6C9" />
        <path fillRule="evenodd" clipRule="evenodd" d="M88.5 0C82.9772 0 78.5 4.47715 78.5 10V84C78.5 89.5229 82.9772 94 88.5 94H169.575C169.238 93.0173 168.939 92.0167 168.681 91H122V53C122 50.2386 119.761 48 117 48H98C95.2386 48 93 50.2386 93 53V91H88.5C84.634 91 81.5 87.866 81.5 84V10C81.5 6.13401 84.634 3 88.5 3H208.5C212.366 3 215.5 6.13401 215.5 10V44.181C216.517 44.439 217.517 44.7375 218.5 45.0746V10C218.5 4.47715 214.023 0 208.5 0H88.5ZM206 43V19C206 15.6863 203.314 13 200 13H136C132.686 13 130 15.6863 130 19V75C130 78.3137 132.686 81 136 81H167.503C167.516 79.9902 167.568 78.9897 167.657 78H137C134.791 78 133 76.2091 133 74V20C133 17.7909 134.791 16 137 16H199C201.209 16 203 17.7909 203 20V43.1152C203.99 43.0389 204.99 43 206 43ZM119 91H96V54C96 52.3431 97.3431 51 99 51H116C117.657 51 119 52.3431 119 54V91ZM147.218 22.4393C147.803 21.8536 148.753 21.8536 149.339 22.4393C149.925 23.0251 149.925 23.9749 149.339 24.5607L141.561 32.3388C140.975 32.9246 140.025 32.9246 139.439 32.3388C138.854 31.753 138.854 30.8033 139.439 30.2175L147.218 22.4393ZM154.996 24.5604C155.581 23.9746 156.531 23.9746 157.117 24.5604C157.703 25.1462 157.703 26.096 157.117 26.6818L143.682 40.1168C143.096 40.7026 142.146 40.7026 141.561 40.1168C140.975 39.531 140.975 38.5812 141.561 37.9955L154.996 24.5604Z" fill="#CACACA" />
        <rect x="114" y="70" width="3" height="6" rx="1.5" fill="#CACACA" />
    </svg>
);

export default Share;
